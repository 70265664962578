import { Box } from '@thebouqs/ui';
import styled from '@emotion/styled';
import { MEDIA_QUERIES } from '@/constants/styles';
import { customShouldForwardProp } from '@/styles/util/shouldForwardProp';

const ProductSummaryContainer = styled(Box, {
  shouldForwardProp: customShouldForwardProp(['theme', 'onSale']),
})`
  .product-summary {
    &__media {
      margin-bottom: 0.625rem;
    }

    &__info {
      position: relative;
    }

    &__name {
      word-break: break-word;
      text-transform: none;
      ${MEDIA_QUERIES.RESPOND_UP.MD} {
        padding-right: ${props => props.isSale && '6em'};
      }
    }
  }
`;
export default ProductSummaryContainer;
