import { Skeleton } from '@thebouqs/ui';

export const SuperHeroMediaSkeleton = (): JSX.Element => {
  /*
  height values were plucked from the dom, when inspecting the size of the images
  in the superhero content block
  */
  return (
    <Skeleton
      width="100%"
      height={['17.25rem', null, '258.5rem']}
      startColor="lightGray"
      endColor="lightestGray"
    />
  );
};
