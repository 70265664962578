import { Product as ProductInterface } from '@/interfaces';
import { getCheapestVariantFullPrice } from '@/utils/productVariantAccessors';
import { Product as SegmentProduct } from '@/store/analytics/AnalyticsProvider.d';

export const mapProductsToSegmentProductArray = (
  products: Array<ProductInterface>
): SegmentProduct[] => {
  return products.map((product, index) => ({
    category: product.category,
    label: product.name,
    name: product.name,
    position: index,
    price: getCheapestVariantFullPrice(product),
    product_id: product.id,
    vase: product.default_vase_id,
  }));
};
