import FooterContentArea from '@/components/ContentAreas/FooterContentArea';
import SocialLinksCopyright from '@/components/SocialLinksCopyright';
import { Box } from '@thebouqs/ui';
import AccessibilityLink, {
  AccessibilityButtonContainer,
} from '@/components/AccessibilityLink';
import useTranslation from '@/hooks/useTranslation';

const PageFooter = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Box>
      <Box
        as="footer"
        px="0"
        py="8"
        sx={{
          borderTop: '1px',
          borderTopStyle: 'solid',
          borderTopColor: 'lightestGray',
        }}>
        <AccessibilityButtonContainer>
          <AccessibilityLink link="#top">
            {t('common.accessibilityLinks.top')}
          </AccessibilityLink>
        </AccessibilityButtonContainer>
        <FooterContentArea />
        <SocialLinksCopyright />
      </Box>
    </Box>
  );
};

export default PageFooter;
