import styled from '@emotion/styled';

export const IconLink = styled('a', {
  shouldForwardProp: () => true,
})`
  svg {
    width: ${({ theme }) => theme.fontSizes.xl};
    height: ${({ theme }) => theme.fontSizes.xl};
  }
  line-height: 0;
  font-size: 0;
  color: transparent;
`;
