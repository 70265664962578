import { BreadcrumbsSchemaProps, BreadcrumbProps } from './BreadcrumbsSchema.d';
import JsonLdScript from '@/components/Seo/JsonLdScript';

const buildBreadcrumbs = (itemListElements: BreadcrumbProps[]) => {
  return itemListElements.map(itemListElement => ({
    '@type': 'ListItem',
    position: itemListElement.position,
    item: {
      '@id': itemListElement.item,
      name: itemListElement.name,
    },
  }));
};

const BreadcrumbsSchema = (props: BreadcrumbsSchemaProps): JSX.Element => {
  const { keyOverride, itemListElements = [] } = props;

  const jslonld = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: buildBreadcrumbs(itemListElements),
  };

  return (
    <JsonLdScript
      scriptContent={jslonld}
      pageName={keyOverride ? keyOverride : ''}
      id="breadcrumbs"
    />
  );
};

export default BreadcrumbsSchema;
