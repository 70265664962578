import { useState, useCallback, useEffect } from 'react';
import { Collapse } from '@chakra-ui/transition';
import { Box, Flex, Heading, Link } from '@thebouqs/ui';
import TranslatedText from '@/components/TranslatedText';
import useTranslation from '@/hooks/useTranslation';
import { downloadStatus } from '@/hooks/useLazyImage/constants';
import useBreakpoint from '@/hooks/useBreakpoint';
import { CategoryBannerBlockStyles } from './CategoryBannerBlock.styles';
import { CategoryBannerBlockProps } from './CategoryBannerBlock.d';
import { CategoryBannerBlockImg } from '@/components/ContentAreas/Blocks/CategoryBannerBlock/CategoryBannerBlockImg';
import { CategoryBannerSkeleton } from './CategoryBannerSkeleton';

const CategoryBannerBlock = (props: CategoryBannerBlockProps): JSX.Element => {
  const [status, setStatus] = useState<string>(downloadStatus.LOADING);
  const { isMobile } = useBreakpoint();
  const [showDescription, setShowDescription] = useState(!isMobile);
  const { t } = useTranslation();

  const onLoad = useCallback(() => {
    setStatus(downloadStatus.OK);
  }, [setStatus]);

  useEffect(() => {
    setShowDescription(!isMobile);
  }, [isMobile]);

  const onMediaRef = useCallback(node => {
    if (!node) {
      return null;
    }

    if (node.complete) {
      // image was acached or prebunddled
      setStatus(downloadStatus.OK);
    }
  }, []);

  const {
    data: {
      cat_description,
      cat_title,
      image,
      image_focus_point,
      text_position,
      text_style,
      use_value_add_link_flag,
      value_add,
      value_add_link,
    }
  } = props;
  const focusPoint = image_focus_point?.toLowerCase();
  const contentPosition = text_position?.toLowerCase();
  const variant = text_style?.toLowerCase();

  const {
    '.category-banner-content-block__img': CategoryBannerImgStyle,
    '.area-block-content': CategoryBannerContentStyle,
    '.category-banner-content-block__title': CategoryBannerTitleStyle,
    '.category-banner-content-block__toggle-description-button':
      CategoryBannerToggleDescriptionButtonStyle,
    '.category-banner-content-block__description':
      CategoryBannerDescriptionStyle,
    '.category-banner-content-block__value-add': CategoryBannerValueAddStyle,
    ...CategoryBannerContainerStyle
  }: any = CategoryBannerBlockStyles({
    variant,
    focusPoint,
    contentPosition,
    showDescription,
    isMobile,
  });

  const ValueAdd = () => {
    if (value_add && value_add_link && use_value_add_link_flag) {
      return (
        <Link {...CategoryBannerValueAddStyle} href={value_add_link}>
          <TranslatedText
            className="category-banner-content-block__value-add"
            as="p"
            fontWeight="semibold"
            textDecoration="underline"
            {...CategoryBannerValueAddStyle}>
            {value_add}
          </TranslatedText>
        </Link>
      );
    }

    return (
      <TranslatedText
        className="category-banner-content-block__value-add"
        as="p"
        fontWeight="semibold"
        {...CategoryBannerValueAddStyle}>
        {!value_add ? t('contentBlocks.categoryValueAdd') : value_add}
      </TranslatedText>
    );
  };

  return (
    <Box
      className="category-banner-block"
      {...CategoryBannerContainerStyle}>
      <CategoryBannerBlockImg
        display={['none', 'block']}
        media={image}
        color="white"
        fontSize="md"
        onLoad={onLoad}
        ref={onMediaRef}
        {...CategoryBannerImgStyle}
      />
      <Flex className="area-block-content" {...CategoryBannerContentStyle}>
        <Heading
          className="category-banner-content-block__title"
          as="h1"
          size="md"
          {...CategoryBannerTitleStyle}>
          {cat_title}
        </Heading>
        <Collapse in={showDescription}>
          <TranslatedText
            className="category-banner-content-block__description"
            {...CategoryBannerDescriptionStyle}>
            {cat_description}
          </TranslatedText>
        </Collapse>
        <TranslatedText
          className="category-banner-content-block__toggle-description-button"
          as="button"
          t={showDescription ? 'common.readLess' : 'common.readMore'}
          {...CategoryBannerToggleDescriptionButtonStyle}
          onClick={() => setShowDescription(!showDescription)}
        />
        <ValueAdd />
      </Flex>
      {!props.data || (status === downloadStatus.LOADING && !isMobile) ? (
        <CategoryBannerSkeleton />
      ) : null}
    </Box>
  );
};

export default CategoryBannerBlock;
