import renderContentAreaBlocks from './ContentAreaBlocks';
import useStatic from '@/hooks/useStatic';
import FullWidthContainer from '@/components/FullWidthContainer';

const HeroRegion = (): JSX.Element => {
  const {
    contentAreas,
    pageData: { name },
  } = useStatic();

  const data = contentAreas?.hero_region;

  const blocks = renderContentAreaBlocks({ data }, name, 'hero-region');

  return (
    <FullWidthContainer className="content-area content-area--hero-region">
      {blocks.length ? blocks : null}
    </FullWidthContainer>
  );
};

export default HeroRegion;
