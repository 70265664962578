import { FunctionComponent, ReactElement } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { SFErrorBoundaryProps } from './ErrorBoundary.d';

function CustomErrorFallback(
  ErrorBoundaryModuleProps: Partial<FallbackProps>,
  fallbackComp: JSX.Element,
  targetComponent: string
) {
  // eslint-disable-next-line no-console
  console.group(targetComponent);
  console.error(ErrorBoundaryModuleProps.error);
  // eslint-disable-next-line no-console
  console.groupEnd();
  return <>{fallbackComp}</>;
}

export const SFErrorBoundary: FunctionComponent<SFErrorBoundaryProps> = ({
  fallbackComponent,
  targetComponent,
  children,
}): ReactElement<any, any> => {
  return (
    <ErrorBoundary
      FallbackComponent={(errorFallbackProps: FallbackProps) =>
        CustomErrorFallback(
          errorFallbackProps,
          fallbackComponent,
          targetComponent
        )
      }>
      {children}
    </ErrorBoundary>
  );
};
