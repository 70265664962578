import { Box, List, ListItem, Link } from '@thebouqs/ui';
import TranslatedText from '@/components/TranslatedText';
import useTranslation from '@/hooks/useTranslation';
import useStatic from '@/hooks/useStatic';
import BreadcrumbsSchema from '@/components/Seo/BreadcrumbsSchema';

// TODO: replace with bouqs-ui Breadcrumb component
const Breadcrumbs = (): JSX.Element => {
  const { t } = useTranslation();
  const {
    pageData: { breadcrumbs: data },
  } = useStatic();

  const itemListElements = [];

  return (
    <Box
      as="nav"
      aria-label={t('aria.label.breadCrumbs')}
      className="breadcrumbs"
      my="1%"
      mb="md">
      <List
        marginLeft="0"
        itemType="http://schema.org/BreadcrumbList"
        display="flex"
        data-testid="breadcrumbs-list">
        {data
          ? data.map((breadcrumb, index) => {
              {
                /* TODO: Handle `NextLink` whenever we have a RUI controlled path (e.g: `/flowers/all`, `/`) */
              }
              const { name, path } = breadcrumb;

              // Handling un-clickable links
              if (!name || !path) {
                return null;
              }

              itemListElements.push({
                position: index + 1,
                item: path,
                name,
              });

              return (
                <ListItem cursor="pointer" key={`${breadcrumb}-${index}`}>
                  <Link href={path}>
                    <TranslatedText
                      size="sm"
                      color="text"
                      textTransform="capitalize"
                      _first={{
                        display: 'inline-block',
                      }}
                      _after={{
                        // eslint-disable-next-line quotes
                        content: `${
                          index < data.length - 1 ? "'\\00BB'" : "''"
                        }`,
                        marginX: '2',
                        fontSize: 'md',
                      }}>
                      <span>{name}</span>
                    </TranslatedText>
                  </Link>
                </ListItem>
              );
            })
          : null}
      </List>
      <BreadcrumbsSchema itemListElements={itemListElements} />
    </Box>
  );
};

export default Breadcrumbs;
