import sanitizeHtml from 'sanitize-html';
import { Box, useStyleConfig } from '@thebouqs/ui';
import { RichTextBlockProps } from './RichTextBlock.d';
import { ContentAreaStyles } from '@/components/ContentAreas/ContentArea.styles';
import { RichTextBlockStyles } from './RichTextBlock.styles';

const RichTextBlock = (props: RichTextBlockProps): JSX.Element => {
  const { data } = props;
  const h1Styles = useStyleConfig('Heading', {
    size: 'lg',
  });
  const h2Styles = useStyleConfig('Heading', {
    size: 'md',
  });
  const h3Styles = useStyleConfig('Heading', {
    size: '2xs',
  });

  const styles = {
    h1: h1Styles,
    h2: { ...h2Styles, fontFamily: 'sans' },
    h3: h3Styles,
    ...ContentAreaStyles,
    ...RichTextBlockStyles,
  };

  return (
    <Box
      className="rich-text-block"
      display={data.mobile_only ? ['block', 'none'] : null}
      py="2xl"
      __css={styles}>
      <Box
        className="area-block-content"
        dangerouslySetInnerHTML={{
          __html: sanitizeHtml(data.html, {
            allowedAttributes: {
              '*': ['class', 'id', 'style'],
              ...sanitizeHtml.defaults.allowedAttributes,
            },
          }),
        }}
        margin="0 auto"
        width={['95%', null, '85%']}
      />
    </Box>
  );
};

export default RichTextBlock;
