type Translator = (...keys: string[]) => string;

/**
 * Return a simple formatter function to simplify translation managing
 * @example
 * const commonTrn = createTranslator('common');
 *
 * commonTrn('yes'); // -> 'common.yes'
 * commonTrn('hello', 'person'); // -> 'common.person'
 * commonTrn('hello.person'); // -> 'common.person'
 */
export function createTranslator(namespace: string): Translator {
  const translator: Translator = (...keys) => `${namespace}.${keys.join('.')}`;
  return translator;
}
