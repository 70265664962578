import { createContext } from 'react';
import { IntersectionObserverProps } from './useObserver.d';
import isBrowser from '@/utils/isBrowser';

export function createIntersectionObserver({
  margin,
  root,
}: IntersectionObserverProps): IntersectionObserver {
  if (isBrowser()) {
    const onIntersect = new CustomEvent('intersects', { bubbles: false });

    const observer = new IntersectionObserver(
      entries => {
        entries.map(entry => {
          if (entry.isIntersecting) {
            entry.target.dispatchEvent(onIntersect);
          }
        });
      },
      {
        rootMargin: margin,
        root,
      }
    );
    return observer;
  }
  return null;
}

export const IntersectionObserverContext = createContext({
  observer: createIntersectionObserver({}),
});
