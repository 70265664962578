import { useToken } from '@thebouqs/ui';

/**
 *
 * @description Creates a Bouqs UI compatible css transition
 * @returns css string
 * @example createTransition('color'); // -> transition: color 0.2s ease-out
 * @example createTransition('opacity', 'long'); // -> transition: opacity 0.3s ease-out
 * @example createTransition('background-color, outline', 'short', 'easeOut'); // transition: background, color 0.1s ease-out;
 */
export function createTransition(
  property: string,
  duration = 'normal',
  easingFunction = 'ease-in-out'
) {
  return (): string => {
    const [durationValue, easingFunctionValue] = useToken(
      // the key within the theme, e.g. `theme.transition`
      'transition',
      // the subkey(s), resolving to e.g. `theme.transition.duration.fast`
      [`duration.${duration}`, `easing.${easingFunction}`]
    );

    return `transition: ${property} ${durationValue} ${easingFunctionValue};`;
  };
}
