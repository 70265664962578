import StaticContext, {
  StaticContextInterface,
} from '@/store/static/StaticContext';
import useSafeContext from './utils/useSafeContext';

function useStatic(): StaticContextInterface {
  const state = useSafeContext<StaticContextInterface>(StaticContext);
  return state;
}

export default useStatic;
