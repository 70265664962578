import useCookies from '@/hooks/useCookies/useCookies';
import { COOKIES } from '@/constants/cookies';
import { UseSignifydReturn } from './useSignifyd.d';

const getUuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export default function useSignifyd(): UseSignifydReturn {
  const { cookies, updateCookie } = useCookies();

  const getSessionId = () => {
    let sessionId = cookies?.window_session_id;

    if (!sessionId) {
      sessionId = `bouqs-${getUuid()}`;
      updateCookie(COOKIES.WINDOW_SESSION_ID, sessionId);
    }

    return sessionId;
  };

  return {
    getSessionId,
  };
}
