import { Skeleton, SkeletonProps } from '@thebouqs/ui';

//Height was derived from rounding down 461/16 where 461 is the desktop height of one product grid picture;
// the ' md ' variable corresponds to the ' largeSizes ' md in the bouqs-ui foundations/sizes.ts file
export const BestSellersBlockSkeleton = (props: SkeletonProps): JSX.Element => (
  <Skeleton
    width="100%"
    data-testid="best-sellers-block-skeleton"
    height="lg"
    startColor="lightGray"
    endColor="lightestGray"
    {...props}
  />
);

export default BestSellersBlockSkeleton;
