import { ProductSummaryPricingProps } from './ProductSummaryPricing.d';
import { SystemStyleObject } from '@thebouqs/ui';
import { COLORS } from '@/constants/styles/colors';

export const styles = (
  props: ProductSummaryPricingProps
): SystemStyleObject => {
  const { showMessage } = props;
  return {
    zIndex: '2',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    color: COLORS.DARKEST_GRAY,
    letterSpacing: '0.5px',
    width: '100%',
    height: '30px',
    padding: '0 0 0.625rem 0',
    margin: '0 0 0.625rem 0',
    borderBottom: `1px solid ${COLORS.LIGHTER_GRAY}`,

    '.product-summary__message': {
      margin: '0 4px 0 0',
    },

    '.product-prices__price': {
      lineHeight: '20px',
    },

    /* Positions price next to bouq name */
    '.price': {
      '+ &': {
        fontWeight: showMessage ? '400' : null,
      },
    },
  };
};
