import { useEffect, useRef } from 'react';
import useIntersectionApi from '@/hooks/useIntersectionApi';
import useAnalytics from '@/hooks/useAnalytics';
import { Props } from './TrackView.d';

const TrackView = ({
  actionName,
  children,
  nonInteraction = 1,
  ...segmentProps
}: Props): JSX.Element => {
  const { track } = useAnalytics();
  const wasTrackEventFired = useRef(false);
  // container for element to observe
  const ref = useRef<HTMLDivElement | null>(null);
  const observer = useIntersectionApi(ref, {
    threshold: 1, // 100% visible in viewport
    freezeOnceVisible: true, // trigger event only once per element
  });

  const isVisible = Boolean(observer?.isIntersecting);

  useEffect(() => {
    if (isVisible && !wasTrackEventFired.current) {
      track(actionName, { ...segmentProps, nonInteraction });
      wasTrackEventFired.current = true;
    }
  }, [
    isVisible,
    actionName,
    segmentProps,
    track,
    nonInteraction,
    wasTrackEventFired,
  ]);

  return <div ref={ref}>{children}</div>;
};

export default TrackView;
