import styled from '@emotion/styled';
import { Box } from '@thebouqs/ui';
import { MEDIA_QUERIES } from '@/constants/styles';

const spinningFlowerInline =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACYAAAAgCAYAAAB+ZAqzAAACSklEQVRYhb2Y/ZGyMBDGfyWkhJRAB5cOpAPpwOvAdHB0cHSgHUgH2sHRgXbg+8cuQ0C4wyS8z0xGzGw2D/utEAcD7IAjcAIuwTrpfhGpOwpOL34AZ+BT95wS6Z89cAPuStJsRcgqoQ4o3zhnEJId8JWZExVioSpBhwEa4EomFx8Ql9gcypCX60gk5xBSueOjIAO5rVAiibFZUszhY6WcRxJqcxiGUvK98swNCZkssEht2jGOkwuSeUYvXFNWKqQeZkGjq1YCP4ilwgus7q9Bx5D1loS4axmb3wFPXkvKVG6KvX7WSLwZ5GWis3V6oUUsN0WNxJpd0NMgBbdUnbWuaNSMu4BBUn+KFnHvg+V29IlY6a5ySeXjqMr6aWLPOE5gKKLoZWeWM7VCQuGdHjyCUSKtKumnibMqvgRyvYtCtEgsLen+FfaX/R9eG3mh+z25k5Kak5vbXwWnyucOOyUQvlmfRRWD5VolsAtkjrw/Lo1wRQJwqXLXKmOC7z4g3texSgk+VVdLQlB7VdwgmdIw38c8kkFfjJtw76opCiUWBcuQqpWSQgkuyXteK3vHfEF9xhLrLwMh17HO9NMLHWJF94dcNBqWrRXizDhRSqQDdIhbj7rmukIULOuGuCKQm06lDnG3JyEb5+BZN8RVSKw9+I+j8o11BbEk34+VVTCk/2xb0vudqrdAyB0yEIKhjza5lLVIzNkEPXvm+20yPEO7sm+c2zFMJZslSPhfxBVpTR+6XPB8QF7gjtS7rCXjLxQMfbWdLI+4LLp5/wMFd6cwDt5xngAAAABJRU5ErkJggg==';

const ProductSummaryMediaContainer = styled(Box, {
  shouldForwardProp: () => true,
})`
  position: relative;
  .product-summary__media {
    &-promotional-badge {
      position: absolute;
      top: 3%;
      left: 3%;
      max-width: 8em;
      height: auto;
      z-index: 2;

      ${MEDIA_QUERIES.RESPOND_UP.LG} {
        max-width: 13.7em;
      }
    }
    &-image {
      width: 100%;
      img,
      div {
        width: 100%;
        background-color: transparent;
        transition: background 1s ease-in;
      }
      &[data-loading='true'] {
        img,
        div {
          background-color: ${({ theme }) => theme.colors.lightestGray};
          padding: 50%;
        }
        &::after {
          @keyframes rotation {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          margin-right: 0;
          margin-bottom: 0;
          content: '';
          margin-top: -${({ theme }) => theme.sizes['4']};
          margin-left: -${({ theme }) => theme.sizes['4']};
          width: ${({ theme }) => theme.sizes['8']};
          height: ${({ theme }) => theme.sizes['8']};
          background: transparent url(${spinningFlowerInline}) center no-repeat;
          animation: rotation 1.1s infinite linear;
        }
      }
    }
    &__unavailable {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 2;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.6);
      color: #fff;
      font-size: 1rem;
    }
  }
`;

export default ProductSummaryMediaContainer;
