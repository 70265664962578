import { useRef, useEffect } from 'react';

// React lifecycle hook that calls a function when the component will unmount.
const useUnmount = (fn: () => void): void => {
  const fnRef = useRef(fn);

  // update the ref each render so if it change the newest callback will be invoked
  fnRef.current = fn;

  useEffect(() => fnRef.current(), []);
};

export default useUnmount;
