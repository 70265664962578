import ROUTES from '@/routes';
import {
  UtilityMenuDataProps,
  GenerateUtilityMenuDataProps,
} from './generateUtilityMenuData.d';

/**
 * Hardcoded list of links used in Top Nav
 *
 * @method
 * @name utilityMenuData
 *
 * @param {function} t - text translation function from useTranslation
 */
const utilityMenuData = ({ t }) => {
  return {
    retailLocationsMenuData: [
      {
        name: t('header.retailLocations'),
        link: ROUTES.retailLocations,
      },
    ],
    trackMenuData: [
      {
        name: t('header.trackOrder'),
        link: ROUTES.trackOrder,
      },
    ],
    cartMenuData: [
      {
        name: t('header.cart'),
        link: ROUTES.cart,
        icon: 'basket',
      },
    ],
    helpMenuData: [
      {
        name: t('common.help'),
        link: ROUTES.helpCenter,
        children: [
          {
            name: t('header.flowerCare'),
            link: ROUTES.flowerCare,
          },
          {
            name: t('header.helpCenter'),
            link: ROUTES.helpCenter,
          },
        ],
      },
    ],
    userMenuData: [
      {
        name: t('common.login'),
        link: ROUTES.login,
        icon: 'account',
      },
    ],
    userMenuDataDrawer: [
      {
        name: t('header.account'),
        link: ROUTES.user.myAccount,
        icon: 'account',
        new_section: true,
        children: [
          {
            name: t('common.login'),
            link: ROUTES.login,
          },
          {
            name: t('header.signUp'),
            link: ROUTES.login,
          },
        ],
      },
    ],
    userMenuDataLoggedIn: [
      {
        name: t('header.account'),
        link: ROUTES.user.myAccount,
        icon: 'account',
        new_section: true,
        children: [
          {
            name: t('header.myAccount'),
            link: ROUTES.user.myAccount,
          },
          {
            name: t('header.orders'),
            link: ROUTES.user.orders,
          },
          {
            name: t('header.paymentmethods'),
            link: ROUTES.user.paymentMethods,
          },
          {
            name: t('header.relationships'),
            link: ROUTES.user.relationships,
          },
          {
            name: t('header.mySubscriptions'),
            link: ROUTES.subscriptions.manage,
          },
          {
            name: t('common.logout'),
            link: ROUTES.logout,
          },
        ],
      },
    ],
  };
};

/**
 * Return array of hardcoded links used to display Help, User, Cart menus in Top Nav
 *
 * @method
 * @name generateUtilityMenuData
 *
 * @param {boolean} isDesktop - from useBreakpoint
 * @param {boolean} isLoggedIn - from useUser
 * @param {boolean} isDrawer - the list of links is different for desktop nav than it is for the mobile drawer nav
 */
export const generateUtilityMenuData = ({
  isDesktop = false,
  isLoggedIn = false,
  isDrawer = false,
  t,
}: GenerateUtilityMenuDataProps): Array<UtilityMenuDataProps> => {
  const {
    retailLocationsMenuData,
    trackMenuData,
    cartMenuData,
    helpMenuData,
    userMenuData,
    userMenuDataDrawer,
    userMenuDataLoggedIn,
  } = utilityMenuData({ t });

  const menuData = isDesktop
    ? [].concat(
        retailLocationsMenuData,
        trackMenuData,
        helpMenuData,
        isLoggedIn ? userMenuDataLoggedIn : userMenuData,
        cartMenuData
      )
    : isDrawer
    ? [].concat(
        isLoggedIn ? userMenuDataLoggedIn : userMenuDataDrawer,
        helpMenuData,
        trackMenuData,
        retailLocationsMenuData
      )
    : cartMenuData;

  return menuData;
};
