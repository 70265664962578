import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Box } from '@thebouqs/ui';
import { PageHeaderStyles } from './PageHeader.styles';
import { HeaderPromo } from '@/components/ContentAreas';
import TopNav from '@/components/TopNav';
import { useScrolling } from '@/hooks/useScrolling';

const offset = 0;

const PageHeader = (): JSX.Element => {
  const headerWrapper = useRef(null);
  const [stickyNavClass, setStickyNavClass] = useState<string>('up');
  const [navbarHeight, setNavbarHeight] = useState<number>(0);
  const { scrollDirection, y } = useScrolling();
  const headerHeight = headerWrapper?.current?.clientHeight;

  useEffect(() => {
    setNavbarHeight(headerHeight);
  }, [headerHeight]);

  useEffect(() => {
    const navClassName =
      y > offset ? `page-header--fixed sticky--scroll-${scrollDirection}` : '';

    setStickyNavClass(navClassName);
  }, [y, scrollDirection]);

  /* Memoizing this because top nav was re-rendering on page scroll */
  const memoizedTopNav = useMemo(() => {
    return <TopNav />;
  }, []);

  return (
    <Box id="page-header-sticky-wrapper" h={`${navbarHeight}px`}>
      <Box
        as="header"
        id="page-header"
        className={`page-header ${stickyNavClass}`}
        sx={PageHeaderStyles}>
        <div className="page-header__top" ref={headerWrapper}>
          <HeaderPromo />
          {memoizedTopNav}
        </div>
      </Box>
    </Box>
  );
};

export default PageHeader;
