import { useEffect } from 'react';
import { IntersectionObserverContext } from './intersectionObserverContext';
import useSafeContext from '../utils/useSafeContext';

export function useObserver(target: HTMLElement): IntersectionObserver {
  const { observer } = useSafeContext(IntersectionObserverContext);

  useEffect(() => {
    if (target) {
      observer?.observe(target);
    }
  }, [target]);

  return observer;
}
