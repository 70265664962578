import React from 'react';
import { ButtonProps } from '@thebouqs/ui';
import AccessibilityLinkButton from './AccessibilityLink.styles';
interface AccessibilityLinkProps extends ButtonProps {
  link: string;
}

const AccessibilityLink = (props: AccessibilityLinkProps): JSX.Element => {
  return <AccessibilityLinkButton {...props} as="a" href={props.link} />;
};

export default AccessibilityLink;
