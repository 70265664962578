import { UseUserInterface } from '@/hooks/useUser';
import { SelectUserType } from './selectUserType.d';

export default function selectUserType(user: UseUserInterface): SelectUserType {
  if (user.isPending || user.fetchError) {
    return {
      isSubscriber: false,
      isLoggedIn: false,
      cartQuantity: 0,
    };
  }
  const { logged_in, is_subscriber, cart_quantity } = user;
  return {
    isSubscriber: is_subscriber,
    isLoggedIn: logged_in,
    cartQuantity: cart_quantity,
  };
}
