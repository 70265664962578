import React from 'react';
import { HStack, Box, Button, Heading } from '@thebouqs/ui';
import ProductSummary from '@/components/ProductSummary';
import { HiddenScrollbarsContainer } from '@/components/HiddenScrollbarsContainer/HiddenScrollbarsContainer.styles';
import useBreakpoint from '@/hooks/useBreakpoint';
import useTranslation from '@/hooks/useTranslation';
import ROUTES from '@/routes';
import { BestSellersComponentProps } from './BestSellersBlock.d';
import { BestSellersBlockSkeleton } from './BestSellersSkeleton';

const BestSellersBlockComponent = ({
  data: { products, header, subheader },
}: Partial<BestSellersComponentProps>): JSX.Element => {
  const { isMobileTablet } = useBreakpoint('sm');
  const { t } = useTranslation();
  products = products?.slice(0, 4);

  return products.length ? (
    <Box as="section" className="best-sellers-block" my="14">
      <Heading
        size={isMobileTablet ? 'sm' : 'md'}
        className="best-sellers-block__header"
        marginBottom="6"
        fontFamily="sans"
        textAlign="center">
        {header ?? t('common.shopBestSellers')}
      </Heading>
      {subheader ? (
        <Heading
          as="p"
          size={isMobileTablet ? '2xs' : 'xs'}
          className="best-sellers-block__subheader"
          marginBottom="md"
          textAlign="center">
          {subheader}
        </Heading>
      ) : null}
      <HiddenScrollbarsContainer
        width="100%"
        minHeight={['xs', null, 'sm']}
        overflow="auto"
        whiteSpace="nowrap">
        <HStack
          className="best-sellers-block__grid"
          spacing={['4', null, null, null, '8']}
          justifyContent="flex-start"
          width="min-content"
          minWidth="100%"
          marginBottom={['8', null, '10', '12']}
          paddingX="4">
          {products.map((bestSellerProduct, index) => {
            return (
              <Box
                className="best-sellers-block__item"
                data-testid="best-sellers-block__item"
                key={`best-sellers-block__item--${bestSellerProduct.id}`}
                minWidth={['56', null, '72', '56']}
                maxWidth={['56', null, '72', '80']}
                _first={{
                  marginLeft: 'auto',
                }}
                _last={{
                  marginRight: 'auto',
                }}>
                <ProductSummary
                  sort="top_sellers"
                  product={bestSellerProduct}
                  productIndex={index}
                  blockCategory
                  displayConfig={{
                    showPromotionalBadge: false,
                  }}
                />
              </Box>
            );
          })}
        </HStack>
      </HiddenScrollbarsContainer>
      <Button
        variant="solid"
        background="primary"
        color="white"
        fontSize="md"
        letterSpacing={['wider', null, null, 'wide']}
        fontWeight={['700', null, null, '600']}
        px="8"
        py="3"
        borderRadius="md"
        as="a"
        href={ROUTES.category.popular}
        display="block"
        width="max-content"
        marginX="auto"
        marginBottom="6"
        _hover={{
          color: 'white',
        }}>
        {t('common.bestSellersCTA')}
      </Button>
    </Box>
  ) : (
    <BestSellersBlockSkeleton />
  );
};

export default BestSellersBlockComponent;
