import { Box, Flex, List, ListItem, Link } from '@thebouqs/ui';
import styled from '@emotion/styled';
import { MobileNavContentBlockProps } from './MobileNavContentBlock.d';

const HiddenScrollbarsContainer = styled(List)`
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const MobileNavContentBlock = ({
  links,
}: MobileNavContentBlockProps): JSX.Element => {
  if (!links) return null;

  const linksArray = Object.values(links);

  return (
    <Box display={['block', null, null, 'none']}>
      <HiddenScrollbarsContainer
        overflowX="scroll"
        sx={{
          borderTop: '1px',
          borderTopStyle: 'solid',
          borderTopColor: 'lightGray',
        }}
        mt={3}
        mb={0}
        ml={2}>
        <Flex display="inline-flex" textAlign="center">
          {linksArray.map(({ link_text, link_address }, index) => (
            <ListItem
              key={`link_${index}`}
              fontWeight={700}
              pt={4}
              minWidth={24}
              whiteSpace="nowrap"
              mr={4}
              lineHeight="24px">
              <Link color="text" href={link_address}>
                {link_text}
              </Link>
            </ListItem>
          ))}
        </Flex>
      </HiddenScrollbarsContainer>
    </Box>
  );
};

export default MobileNavContentBlock;
