import styled from '@emotion/styled';
import { Box } from '@thebouqs/ui';

const ProductSummaryAvailabilityContainer = styled(Box, {
  shouldForwardProp: () => true,
})`
  position: relative;
  margin-top: ${({ theme }) => theme.sizes['2.5']};
`;

const ProductSummaryAvailabilityLabel = styled(Box, {
  shouldForwardProp: () => true,
})`
  background: #e0e7d4;
  color: #333333;
  padding: ${({ theme }) => theme.sizes['0']} ${({ theme }) => theme.sizes['1']};
  border-radius: ${({ theme }) => theme.sizes['0.5']};
  display: inline-block;

  @media screen and (min-width: 48rem) {
    padding: ${({ theme }) => theme.sizes['1']};
  }
`;

export { ProductSummaryAvailabilityContainer, ProductSummaryAvailabilityLabel };
