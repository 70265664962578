import { Skeleton, SkeletonProps } from '@thebouqs/ui';

export const ContentAreaSkeleton = (props: SkeletonProps): JSX.Element => (
  <Skeleton
    height={['auto', 'auto', 'auto', '25.81rem']}
    width={['95%', '85%', '85%', '82.25rem']}
    startColor="lightGray"
    endColor="lightestGray"
    {...props}
  />
);

export default ContentAreaSkeleton;
