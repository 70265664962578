import { createIcon } from '@thebouqs/icons';

const InstagramIcon = createIcon({
  displayName: 'instagramIcon',
  viewBox: '0 0 32 32',
  path: (
    <path
      fill="currentColor"
      d="M16 19.136c1.728 0 3.136-1.408 3.136-3.136 0-0.672-0.224-1.312-0.608-1.824-0.576-0.8-1.504-1.312-2.56-1.312s-1.984 0.512-2.56 1.312c-0.384 0.512-0.608 1.152-0.608 1.824 0.064 1.728 1.472 3.136 3.2 3.136zM22.72 12.16v-3.008h-2.912l-0.064 3.008zM16 0.32c-8.64 0-15.68 7.040-15.68 15.68s7.040 15.68 15.68 15.68c8.64 0 15.68-7.040 15.68-15.68s-7.040-15.68-15.68-15.68zM24.96 14.176v7.296c0 1.888-1.6 3.488-3.488 3.488h-10.944c-1.888 0-3.168-1.6-3.168-3.488v-10.944c0-1.888 1.28-3.488 3.168-3.488h10.944c1.888 0 3.488 1.6 3.488 3.488v3.648zM20.864 15.968c0 2.688-2.176 4.896-4.864 4.896s-4.864-2.208-4.864-4.896c0-0.64 0.128-1.216 0.352-1.856h-2.528v7.392c0 0.928 0.608 1.888 1.568 1.888h10.944c0.928 0 1.568-0.96 1.568-1.888v-7.424h-2.528c0.224 0.64 0.352 1.216 0.352 1.888z"
    />
  ),
});

export default InstagramIcon;
