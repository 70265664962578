import sanitizeHtml from 'sanitize-html';
import { JsonLdScriptProps } from './JsonLdScript.d';

const JsonLdScript = ({
  pageName = 'page-key',
  scriptContent,
  id,
}: JsonLdScriptProps): JSX.Element => {
  return (
    <script
      type="application/ld+json"
      key={`${id}-schema-${pageName}`}
      dangerouslySetInnerHTML={{
        __html: sanitizeHtml(JSON.stringify(scriptContent, null, 2), {
          allowedTags: [],
          allowedAttributes: {},
        }),
      }}
      id={`${id}-schema-${pageName}`}
    />
  );
};

export default JsonLdScript;
