import { Box } from '@thebouqs/ui';
import { VisuallyHiddenProps } from './VisuallyHidden.d';
import { VisuallyHiddenStyles } from './VisuallyHidden.styles';

// TODO: Move this to Bouqs UI https://chakra-ui.com/docs/disclosure/visually-hidden
const VisuallyHidden = ({ children }: VisuallyHiddenProps): JSX.Element => {
  return (
    <Box as="span" sx={VisuallyHiddenStyles}>
      {children}
    </Box>
  );
};
export default VisuallyHidden;
