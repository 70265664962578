import AnalyticsContext, {
  AnalyticsContextInterface,
} from '@/store/analytics/AnalyticsContext';
import useSafeContext from './utils/useSafeContext';

function useAnalytics(): AnalyticsContextInterface {
  const state = useSafeContext<AnalyticsContextInterface>(AnalyticsContext);
  return state;
}

export default useAnalytics;
