import styled from '@emotion/styled';
import { Button } from '@thebouqs/ui';
import { MEDIA_QUERIES } from '../../constants/styles';
import { customShouldForwardProp } from '@/styles/util/shouldForwardProp';

/*TODO: once button in bouqs-ui has all the same styling features applied here
remove styles and use button from design system in AccessibilityLink.tsx
*/
const AccessibilityLinkButton = styled(Button, {
  shouldForwardProp: customShouldForwardProp(['theme']),
})`
  position: absolute;
  left: -100%;
  transform: translateY(-100%);
  transition: transform 0.2s ease-out;
  ${MEDIA_QUERIES.RESPOND_UP.LG} {
    &:focus,
    &:active {
      border: 1px solid ${({ theme }) => theme.colors.lightestGray};
      position: static;
      transform: translateY(0%);
      text-decoration: none;
    }
  } ;
`;

export const AccessibilityButtonContainer = styled('div', {
  shouldForwardProp: customShouldForwardProp(['theme']),
})`
  positon: relative;
  left: 0;
  right: 0;
  width: 100%;
`;

export default AccessibilityLinkButton;
