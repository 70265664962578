import { createIcon } from '@thebouqs/icons';

const PinterestIcon = createIcon({
  displayName: 'PinterestIcon',
  viewBox: '0 0 32 32',
  path: (
    <path
      fill="currentColor"
      d="M16 0.32c-8.672 0-15.68 7.008-15.68 15.68s7.008 15.68 15.68 15.68c8.672 0 15.68-7.008 15.68-15.68s-7.008-15.68-15.68-15.68zM17.536 21.28c-1.44-0.096-2.016-0.832-3.136-1.504-0.64 3.232-1.376 6.304-3.616 7.936-0.672-4.864 0.992-8.512 1.792-12.416-1.344-2.272 0.16-6.784 2.976-5.696 3.488 1.376-3.008 8.384 1.344 9.28 4.544 0.928 6.4-7.904 3.584-10.752-4.064-4.16-11.84-0.096-10.88 5.824 0.224 1.44 1.728 1.888 0.608 3.872-2.624-0.576-3.392-2.624-3.296-5.376 0.16-4.48 4.032-7.648 7.936-8.064 4.928-0.544 9.536 1.792 10.176 6.432 0.672 5.216-2.24 10.848-7.488 10.464z"
    />
  ),
});

export default PinterestIcon;
