import styled from '@emotion/styled';

const PriceSpanContainer = styled('span')<any>`
  text-decoration: ${props => {
    return props.inactive ? 'line-through' : null;
  }};

  + .price {
    margin-left: ${({ theme }) => {
      return theme.sizes['1'];
    }};
  }
`;
export default PriceSpanContainer;
