import AccordionBlock from './Blocks/AccordionBlock';
import BestSellersBlock from './Blocks/BestSellersBlock';
import CategoryBannerBlock from './Blocks/CategoryBannerBlock';
import HtmlBlock from './Blocks/HtmlBlock';
import RibbonBlock from './Blocks/RibbonBlock';
import ExitIntentBlock from './Blocks/ExitIntentBlock';
import RichTextBlock from './Blocks/RichTextBlock';
import SuperHeroBlock from './Blocks/SuperHeroBlock';
import Olapic from './Blocks/OlapicBlock';
import CardsCarouselBlock from './Blocks/CardsCarouselBlock';
import { ContentObject } from '@/interfaces';
import McodeRibbonBlock from './Blocks/McodeRibbonBlock';

const blockTypesMap = {
  accordion: AccordionBlock,
  best_sellers_product_list: props => <BestSellersBlock {...props} />,
  cards_carousel: CardsCarouselBlock,
  category_banner: CategoryBannerBlock,
  exit_intent: ({ data, key }) => <ExitIntentBlock data={data} key={key} />,
  html: HtmlBlock,
  olapic_carousel: ({ data, key }) => <Olapic data={data} key={key} />,
  // Note: smart components need to be mounted, if needed, _key (not key, it is reserved by react)
  ribbon: ({ data, category, key }) => (
    <RibbonBlock _key={key} category={category} data={data} />
  ),
  mcode_ribbon: props => {
    return <McodeRibbonBlock {...props} />;
  },
  rich_text: RichTextBlock,
  super_hero: SuperHeroBlock,
};

const renderContentAreaBlocks = (
  { data }: { data: ContentObject[] },
  category: string,
  area: string
): JSX.Element[] => {
  //TODO: revisit some endpoints might not be returning an array of objs
  if (!Array.isArray(data)) {
    return [];
  }

  const blocks = data.reduce(
    (acc: JSX.Element[], curr: ContentObject, index: number) => {
      if (!curr.active) {
        return acc;
      }

      const BlockType = blockTypesMap[curr?.type_id];

      // ToDo: remove when all block types are supported
      if (BlockType) {
        const key = `${area}__${curr.type_id}-${index}`;
        const data = curr.data;
        acc.push(
          <BlockType data={data} key={key} category={category} region={area} />
        );
      }

      return acc;
    },
    []
  );

  return blocks;
};

export default renderContentAreaBlocks;
