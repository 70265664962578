import React from 'react';
import TranslatedText from '@/components/TranslatedText';
import { roundNumberUp } from '@/utils';
import PriceSpanContainer from './Price.styles';
import { PriceProps } from './Price.d';

const Price = (props: PriceProps): JSX.Element => {
  const {
    children,
    inactive,
    discounted,
    estimated,
    showMessage,
    color,
    ...otherPriceProps
  } = props;
  const price = estimated ? roundNumberUp(children) : children;
  /* Price unit and price number are separate elements to match legacy RoR app
 and allow for different currencies. Sharing props since styles should be the same. */
  // TODO: fix typescript errors so we can add fontSize to this
  const textProps = {
    color: inactive ? 'inactive' : discounted ? 'primary' : color,
    fontWeight: inactive || showMessage ? '400' : '600',
    lineHeight: 'none',
  };

  return (
    <PriceSpanContainer className="price" inactive={inactive}>
      <TranslatedText
        as="span"
        className="price__unit"
        fontSize={['sm', null, 'md']}
        {...otherPriceProps}
        {...textProps}>
        $
      </TranslatedText>
      <TranslatedText
        as="span"
        className="price__number"
        fontSize={['sm', null, 'md']}
        {...otherPriceProps}
        {...textProps}>
        {price}
      </TranslatedText>
    </PriceSpanContainer>
  );
};

Price.defaultProps = {
  inactive: false,
  discounted: false,
  estimated: true,
  showMessage: false,
};

export default Price;
