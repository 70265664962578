import { Box } from '@thebouqs/ui';
import renderContentAreaBlocks from './ContentAreaBlocks';
import TranslatedText from '@/components/TranslatedText';
import useStatic from '@/hooks/useStatic';

const ContentArea1 = (): JSX.Element => {
  const {
    pageData: { name },
    contentAreas,
  } = useStatic();

  const data = contentAreas?.area_1;

  const blocks = renderContentAreaBlocks({ data }, name, 'area-1');

  return (
    <Box className="content-area content-area--1">
      {blocks.length ? blocks : <TranslatedText as="h1">{name}</TranslatedText>}
    </Box>
  );
};

export default ContentArea1;
