import React from 'react';
import { DateTime } from 'luxon';
import useTranslation from '@/hooks/useTranslation';
import TranslatedText from '@/components/TranslatedText';
import {
  ProductSummaryAvailabilityLabel,
  ProductSummaryAvailabilityContainer,
} from './ProductSummaryAvailability.styles';
import { ProductSummaryAvailabilityProps } from './ProductSummaryAvailability.d';

const DATE_FORMAT = 'EEE MM/dd';

export default function ProductSummaryAvailability(
  props: ProductSummaryAvailabilityProps
): JSX.Element {
  const { date } = props;

  const { t } = useTranslation();

  if (!date) return null;

  const formattedDate = DateTime.fromISO(date)
    .toFormat(DATE_FORMAT)
    .toUpperCase();

  return (
    <ProductSummaryAvailabilityContainer>
      <ProductSummaryAvailabilityLabel>
        <TranslatedText
          as="span"
          color="text"
          fontSize={['xs', null, 'sm']}
          fontWeight="600"
          letterSpacing="normal">
          {t('catalog.firstAvailableDate', { date: formattedDate })}
        </TranslatedText>
      </ProductSummaryAvailabilityLabel>
    </ProductSummaryAvailabilityContainer>
  );
}
