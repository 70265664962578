// Modified from https://github.com/garmeeh/next-seo/blob/master/src/jsonld/faqPage.tsx
// Not using next-seo package due to a tree-shaking bug: https://github.com/garmeeh/next-seo/issues/663
import JsonLdScript from '@/components/Seo/JsonLdScript';
import { FaqSchemaProps, QuestionProps } from './FaqSchema.d';

const buildQuestions = (mainEntity: QuestionProps[]) => {
  return mainEntity.map(question => ({
    '@type': 'Question',
    name: question?.questionName,
    acceptedAnswer: {
      '@type': 'Answer',
      text: question?.acceptedAnswerText,
    },
  }));
};

// TODO:
// * refactor using https://nextjs.org/docs/basic-features/script once SSR is supported for inline scripts
// * evaluate whether we should nest this within <Head>. Left it out for now in case we client-side render some blocks.
const FaqSchema = (props: FaqSchemaProps): JSX.Element => {
  const { keyOverride, mainEntity = [] } = props;

  const jslonld = {
    '@context': 'https://schema.org/',
    '@type': 'FAQPage',
    mainEntity: buildQuestions(mainEntity),
  };

  return (
    <JsonLdScript
      scriptContent={jslonld}
      pageName={keyOverride ?? ''}
      id="faq"
    />
  );
};

export default FaqSchema;
