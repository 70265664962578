import React from 'react';
import { List } from '@thebouqs/ui';
import { SFErrorBoundary } from '@/components/ErrorBoundary';
import MenuItemErrorFallback from '@/components/TopNav/MenuItemErrorFallback';
import TopMenu from './TopMenu';
import { NavHeaderMenuProps } from './NavHeaderMenu.d';

const NavHeaderMenu = ({ isDesktop }: NavHeaderMenuProps): JSX.Element => {
  return (
    <List
      m="0"
      display="inline-flex"
      alignItems="center"
      justifyContent={['flex-start', 'space-evenly']}
      className="nav-header-menu">
      <SFErrorBoundary
        fallbackComponent={<MenuItemErrorFallback />}
        targetComponent="NavHeaderMenu">
        <TopMenu isDesktop={isDesktop} />
      </SFErrorBoundary>
    </List>
  );
};

export default NavHeaderMenu;
