import React from 'react';
import BestSellersBlockComponent from './BestSellersBlock.component';
import TrackView from '@/components/Track/TrackView';
import { mapProductsToSegmentProductArray } from '@/utils/analytics';
import { BestSellersProps } from './BestSellersBlock.d';

const BestSellersBlock = (props: BestSellersProps): JSX.Element => {
  const { data } = props;
  const trackViewProps = {
    actionName: 'Product List Viewed',
    category: props.category || 'All',
    list_id: 'Best Sellers Content Block',
    product_category: props.category || 'All',
  };
  return (
    data?.products && (
      <TrackView
        {...trackViewProps}
        products={mapProductsToSegmentProductArray(data.products)}>
        <BestSellersBlockComponent data={data} />
      </TrackView>
    )
  );
};

export default BestSellersBlock;
