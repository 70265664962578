import { IconButton } from '@thebouqs/ui';
import { WarningIcon } from '@thebouqs/icons';

const MenuItemErrorFallback = (): JSX.Element => {
  return (
    <IconButton
      isLoading={true}
      variant="unstyled"
      aria-label="Error"
      icon={<WarningIcon />}
    />
  );
};

export default MenuItemErrorFallback;
