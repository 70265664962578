import { useEffect } from 'react';
import isBrowser from '@/utils/isBrowser';
import { BP_VALUES } from '@/constants/styles/breakpoints';
import useRafState from '../utils/useRafState';
import { Dimensions } from './useWindowDimensions.d';

function getWindowDimensions(): Dimensions {
  const isClient = isBrowser();
  if (isClient) {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
      isWindow: true,
      isMobile: width < BP_VALUES.LG,
    };
  }

  return { width: 0, height: 0, isWindow: false, isMobile: false };
}

export function useWindowDimensions(): Dimensions {
  const [windowDimensions, setWindowDimensions] = useRafState<Dimensions>(
    getWindowDimensions()
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [setWindowDimensions]);

  return windowDimensions;
}
