import { Box } from '@thebouqs/ui';
import styled from '@emotion/styled';
import { BP } from '@/constants/styles/breakpoints';

const NavLogoContainer = styled(Box, {
  shouldForwardProp: () => true,
})`
  @media (max-width: 1023px) and (min-width: ${BP.LG}) {
    margin-left: -8px !important;
    left: 0 !important;
  }
`;

export default NavLogoContainer;
