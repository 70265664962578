import { useState } from 'react';
import { browserCookies } from '@/lib/bouqsCookies';
import { UseCookiesReturns } from './useCookies.d';

export default function useCookies(): UseCookiesReturns {
  const [cookies, setCookies] = useState(() => browserCookies.getAll());

  const refreshCookies = () => {
    setCookies(browserCookies.getAll());
  };

  const updateCookie = (key, value, options) => {
    browserCookies.set(key, value, options);
  };

  const deleteCookie = (key, options) => {
    browserCookies.remove(key, options);
  };

  return {
    cookies,
    refreshCookies,
    updateCookie,
    deleteCookie,
  };
}
