import { BP_VALUES } from '@/constants/styles/breakpoints';
import { SF_URL } from '@/constants/env';

import {
  MakeImageSourceSetBpConfig,
  MakeImageSourceSetConfig,
} from './imageUtilities.d';

function getConfigString(
  globalConfig: MakeImageSourceSetConfig,
  bpConfig: MakeImageSourceSetBpConfig,
  screenW: number
): string {
  let configString = '';
  function augmentConfigString(value: string) {
    configString = `${configString}${value},`;
    return;
  }

  if (!globalConfig) {
    augmentConfigString(`width=${screenW}`);
    return configString;
  }

  const aspectRatio = bpConfig?.aspectRatio ?? globalConfig.aspectRatio;
  const fit = bpConfig?.fit ?? globalConfig.fit;
  const quality = bpConfig?.quality ?? globalConfig.quality;
  const size = bpConfig?.size;

  // aspectRatio
  if (aspectRatio) {
    if (aspectRatio === 'squared') {
      if (size) {
        augmentConfigString(`height=${size}`);
      } else {
        augmentConfigString(`height=${screenW}`);
      }
    }
  }

  //fit
  if (fit) {
    augmentConfigString(`fit=${fit}`);
  }

  //quality
  if (quality) {
    augmentConfigString(`quality=${quality}`);
  }

  //size
  if (size) {
    augmentConfigString(`width=${size}`);
  } else {
    augmentConfigString(`width=${screenW}`);
  }

  return configString;
}

export function makeImageSourceSet(
  src: string,
  /**
   * Set a custom size for each breakpoint or do some changes using cloudflare's API
   * Checkout the docs from cloudflare https://developers.cloudflare.com/images/responsive-images
   */
  config?: MakeImageSourceSetConfig
): string[] {
  // produces [base, options+path] see: https://developers.cloudflare.com/images/responsive-images
  const isSvg = src?.indexOf('.svg') > -1;
  const hasMissingConfig = !config && !config.bpConfig;
  if (isSvg || hasMissingConfig) {
    return [];
  }

  const breakpoints = Object.keys(BP_VALUES);
  const configBps = Object.keys(config.bpConfig);

  return breakpoints
    .filter(bpSize => configBps.includes(bpSize))
    .map(size => {
      const bp = BP_VALUES[size];

      const bpConfig = config?.bpConfig && config.bpConfig[size];
      const configSize = bpConfig?.size;

      const configString = getConfigString(config, bpConfig, bp);

      return `${SF_URL}/cdn-cgi/image/${configString}format=auto${src} ${configSize}w`;
    })
    .filter(Boolean);
}

/**
 * Get the mediaUrl from an `bouqs-cdn.com/media/{mediaUrl}` URL ready to be used with the `makeImageSourceSet` function
 */
export function getMediaUrl(media: string): string | null {
  const mediaArr = media.match(/\/media\/.*/);

  if (Array.isArray(mediaArr)) {
    return mediaArr[0];
  }

  console.warn('[getMediaString] cannot find media match for ' + media);
  return media;
}
