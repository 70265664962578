import { createTransition } from '@/styles/util';

export const PageHeaderStyles = {
  transition: 'height 0.4s',
  zIndex: 'navbar',
  position: 'relative',

  '&.page-header': {
    '&--fixed': {
      position: 'fixed',
      top: 0,
      right: 0,
      left: 0,
      backgroundColor: 'mainBackground',
      ...createTransition('height', undefined, 'ease-in-out'),
    },

    '&.sticky--scroll-down': {
      transform: 'translateY(-100%)',
    },

    '&.sticky--scroll-up': {
      boxShadow: '0 1px 1px -1px black.15',
    },
  },
};
