import React from 'react';
import Price from '@/components/ProductSummary/Price';
import { ProductSummaryMessageProps } from './ProductSummaryMessage.d';
import TranslatedText from '@/components/TranslatedText';
import { styles } from './ProductSummaryMessage.styles';
import { Box } from '@thebouqs/ui';

const ProductSummaryMessage = (
  props: ProductSummaryMessageProps
): JSX.Element => {
  const { showSubscriberDiscount, purchasable, prices } = props;

  if (!purchasable) {
    return (
      <TranslatedText
        as="span"
        color="inactive"
        fontSize={['xs', null, 'md']}
        fontWeight="400"
        letterSpacing="normal"
        className="product-summary__message--unavailable"
        t="catalog.checkBackAvailability"
      />
    );
  }

  if (!showSubscriberDiscount) return null;

  const subscriberMessage = {
    id: 'catalog.subscribeMessage',
    values: { number: 30 },
  };

  return (
    <Box sx={styles()}>
      <TranslatedText
        as="span"
        color="primary"
        fontWeight="semibold"
        fontSize={['xs', null, 'md']}
        letterSpacing="normal"
        className="product-summary__message"
        t={subscriberMessage}
      />
      <div className="product-summary__message__from">
        <TranslatedText
          as="span"
          color="primary"
          fontWeight="semibold"
          fontSize={['xs', null, 'sm']}
          letterSpacing="normal"
          t="catalog.from"
        />
        <Price>{prices?.subscription_discount}</Price>
      </div>
    </Box>
  );
};

export default ProductSummaryMessage;
