import React from 'react';
import dynamic from 'next/dynamic';
import { Flex, Box } from '@thebouqs/ui';
import NavLogo from '../NavLogo';
import NavHeaderMenu from './NavHeaderMenu';
import { NavHeaderProps } from './NavHeader.d';

const DrawerMenu = dynamic(() => import('../DrawerMenu'), {
  ssr: false,
});

const NavHeader = ({
  isDesktop,
  isMobileTablet,
}: NavHeaderProps): JSX.Element => {
  return (
    <Flex
      className="page-header__navigation"
      align="center"
      justify="flex-end"
      flexDirection="row"
      width="full"
      maxWidth="8xl"
      height={['16', null, null, '20']}
      paddingX={[null, null, null, '6']}
      marginX="auto"
      position="relative">
      {isMobileTablet && (
        <Box className="page-header__drawer">
          <DrawerMenu />
        </Box>
      )}
      <NavLogo />
      <NavHeaderMenu isDesktop={isDesktop} />
    </Flex>
  );
};

export default NavHeader;
