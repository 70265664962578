// @IDEA: This could be enhanced with onIdle or more configurations like this jQuery function https://github.com/henriqueboaventura/jquery.idle/blob/master/jquery.idle.js
import { useEffect, useRef, useState } from 'react';
import { UseIdleProps } from './useIdle.d';

// GLOBALS
const SF_IDLE_TIME = 30000;
const events = ['mousemove', 'keydown', 'mousedown', 'touchstart'];

/**
 * Set an idle time in ms to wait and continuously checks if the time has been reached
 * checks for mousemove, keydown, mousedown and touchstart events
 * @default idleTime 30000
 */
export function useIdle(props?: UseIdleProps): boolean {
  const idleTime = props?.idleTime || SF_IDLE_TIME;

  const [idle, setIdle] = useState(false);

  const timeout = () => {
    const id = setTimeout(() => setIdle(true), idleTime);
    return id;
  };

  const timeoutId = useRef(timeout());

  const restartTimeout = () => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }

    timeoutId.current = timeout();
  };

  useEffect(() => {
    const handlers = events.map(() => restartTimeout);
    handlers.forEach((handler, index) =>
      document.body.addEventListener(events[index], handler, false)
    );

    return () => {
      clearTimeout(timeoutId.current);
      handlers.forEach((handler, index) =>
        document.body.removeEventListener(events[index], handler, false)
      );
    };
  });

  return idle;
}
