import sanitizeHtml from 'sanitize-html';
import { Box, useStyleConfig } from '@thebouqs/ui';
import { HtmlBlockProps } from './HtmlBlock.d';
import { ContentAreaStyles } from '@/components/ContentAreas/ContentArea.styles';
import { FooterStyles } from '../../FooterContentArea/Footer.styles';

const HtmlBlock = (props: HtmlBlockProps): JSX.Element => {
  const { data } = props;

  const buttonStyles = useStyleConfig('Button', {
    variants: 'solid',
    colorScheme: 'green',
    size: 'sm',
  });
  const h1Styles = useStyleConfig('Heading', {
    size: 'lg',
  });
  const h2Styles = useStyleConfig('Heading', {
    size: 'md',
  });
  const h3Styles = useStyleConfig('Heading', {
    size: '2xs',
  });

  const styles = {
    h1: h1Styles,
    h2: { ...h2Styles, fontFamily: 'sans' },
    h3: h3Styles,
    '.button': buttonStyles,
    ...ContentAreaStyles,
    ...FooterStyles,
  };

  return (
    <Box
      className="html-block"
      display={data.mobile_only ? ['block', 'none'] : null}
      data-testid="html-block"
      __css={styles}>
      <Box
        className="area-block-content"
        dangerouslySetInnerHTML={{
          __html: sanitizeHtml(data.html, {
            allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img']),
            allowedAttributes: {
              '*': ['class', 'id', 'style'],
              ...sanitizeHtml.defaults.allowedAttributes,
            },
          }),
        }}
      />
    </Box>
  );
};

export default HtmlBlock;
