import { SystemStyleObject } from '@thebouqs/ui';
import { COLORS } from '@/constants/styles/colors';

export const styles = (): SystemStyleObject => {
  return {
    display: 'flex',
    flexDirection: 'column',

    '.product-summary__message': {
      color: COLORS.PRIMARY,
      lineHeight: '20px',
      margin: '0 0 4px',
    },

    '.product-summary__message__from': {
      height: '20px',

      '.chakra-text': {
        fontWeight: 400,
        color: COLORS.DARKEST_GRAY,
      },

      '.price': {
        margin: '0 0 0 4px',

        '.chakra-text': {
          color: COLORS.PRIMARY,
          fontWeight: 600,
          letterSpacing: '0.5px',
        },
      },
    },
  };
};
