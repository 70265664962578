import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
} from '@thebouqs/ui';
import { AccordionBlockProps } from './AccordionBlock.d';
import FaqSchema from '@/components/Seo/FaqSchema';

const buildStructuredItem = item => {
  return {
    questionName: `${item?.heading}`,
    acceptedAnswerText: `${item?.body_text}`,
  };
};

const AccordionBlock = (props: AccordionBlockProps): JSX.Element => {
  const {
    data: { accordion_items, is_faq },
  } = props;
  const faqStructuredData = [];

  return (
    accordion_items && (
      <section
        className="accordion-block"
        data-testid="accordion-block">
        <Accordion allowToggle={true}>
          {Object.keys(accordion_items).map((objKey, index) => {
            const headingItemProps = { tag: index < 1 ? 'h1' : 'h2' };

            if (is_faq && index > 0) {
              faqStructuredData.push(
                buildStructuredItem(accordion_items[objKey])
              );
            }

            return (
              <AccordionItem
                key={`${accordion_items[objKey]?.heading}-${index}`}
                id={`section-${index}`}>
                <AccordionItemHeading
                  title={accordion_items[objKey]?.heading}
                  {...headingItemProps}
                />
                <AccordionItemPanel
                  bodyText={accordion_items[objKey]?.body_text}
                  hasHtml={true}
                />
              </AccordionItem>
            );
          })}
        </Accordion>
        {is_faq && (
          <FaqSchema mainEntity={faqStructuredData} />
        )}
      </section>
    )
  );
};

export default AccordionBlock;
