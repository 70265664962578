import { createIcon } from '@thebouqs/ui';

// TODO: move this to bouqs-ui
const SortIcon = createIcon({
  displayName: 'SortIcon',
  viewBox: '0 0 21 18',
  path: (
    <g id="V2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="SF082018" transform="translate(-342.000000, -254.000000)">
        <g id="SORT" transform="translate(292.000000, 252.500000)">
          <g id="Group-3" transform="translate(49.500000, 1.204317)">
            <g
              id="Page-1"
              transform="translate(0.898702, 0.204317)"
              fill="currentColor">
              <path
                d="M4.09728626,15.4624698 L4.09728626,0.739770273 C4.09728626,0.37929115 4.4157407,0.0874505065 4.78432223,0.0874505065 C5.1644303,0.0874505065 5.47216237,0.36734297 5.47216237,0.739770273 L5.47216237,15.4645035 L8.13291889,12.9411497 C8.39776147,12.6910005 8.83657453,12.7019318 9.09739622,12.9482677 C9.36545551,13.2035012 9.36438328,13.6163489 9.10570606,13.8626848 L4.78217775,17.9619273 L3.81046282,17.0403921 L3.81367953,17.0375958 L0.461866159,13.8578547 C0.202116706,13.6125357 0.201044468,13.1984168 0.469103759,12.9442003 C0.72992545,12.6968475 1.16954269,12.6859162 1.43358109,12.9363195 L4.09728626,15.4624698 Z"
                id="Fill-4"></path>
            </g>
            <path
              d="M10.6608849,2 L20.1895885,2"
              id="Stroke-1"
              stroke="currentColor"
              strokeWidth="1.68799996"
              strokeLinecap="round"
              strokeLinejoin="round"></path>
            <path
              d="M10.6608849,5.29568333 L16.629493,5.29568333"
              id="Stroke-2"
              stroke="currentColor"
              strokeWidth="1.68799996"
              strokeLinecap="round"
              strokeLinejoin="round"></path>
            <path
              d="M10.6608849,9 L14.2566322,9"
              id="Stroke-3"
              stroke="currentColor"
              strokeWidth="1.68799996"
              strokeLinecap="round"
              strokeLinejoin="round"></path>
          </g>
        </g>
      </g>
    </g>
  ),
});

export default SortIcon;
