import { SF_API_V3 } from '@/constants/env';
import isBrowser from '@/utils/isBrowser';
import axios, { AxiosRequestConfig } from 'axios';
import { endpointResponseLogging } from './util';

export const CLOUDFLARE_USER_AGENT = 'bouqs-sf-ui';

const serverConfig = {
  headers: {
    'User-Agent': CLOUDFLARE_USER_AGENT,
  },
  // Uncomment for local development with Rails SF
  // proxy: { host: 'nginx' },
};

const browserConfig = {};

/**
 * axios instance that adds cloudflare friendly headers
 */
export const betch = axios.create({
  baseURL: SF_API_V3,
  ...(isBrowser() ? browserConfig : serverConfig),
});

/**
 * Prevent error responses from crashing the app by returning undefined
 */
export async function _tryFetch(
  url: string,
  options?: AxiosRequestConfig
): Promise<any> {
  try {
    const { data } = await betch.get(url, options);
    endpointResponseLogging(data, url);
    return data;
  } catch (error) {
    console.error(error);
  }
}

export default betch;
