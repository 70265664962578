import { Fragment } from 'react';
import useStatic from '@/hooks/useStatic';

import renderContentAreaBlocks from '../ContentAreaBlocks';

const FooterContentArea = (): JSX.Element => {
  const { contentAreas, pageData } = useStatic();

  const name = pageData?.name ?? 'footer-content-area';

  const data = contentAreas?.footer_navigation;

  const blocks = renderContentAreaBlocks({ data }, name, 'footer-area');

  return data ? <Fragment>{blocks}</Fragment> : null;
};

export default FooterContentArea;
