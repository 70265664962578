import { useMemo } from 'react';
import {
  Box,
  Text,
  Heading,
  Button,
  List,
  LinkBox,
  LinkOverlay,
} from '@thebouqs/ui';
import { createBouqsCdnIcon } from '@thebouqs/icons';
import {
  CardsCarouselBlockProps,
  CardsCarouselItem,
} from './CardsCarouselBlock.d';
import { HiddenScrollbarsContainer } from '@/components/HiddenScrollbarsContainer/HiddenScrollbarsContainer.styles';
import { ContentAreaStyles } from '@/components/ContentAreas/ContentArea.styles';

const CardsCarouselBlock = (props: CardsCarouselBlockProps): JSX.Element => {
  const { data } = props;
  const styles = {
    ...ContentAreaStyles,
  };

  const items = useMemo(() => {
    const values = Object.values(data.carousel_items);
    const proportionalWidth = `${85 / values.length}vw`;

    return values
      .filter((item: CardsCarouselItem) => item.heading && item.text)
      .map((item: CardsCarouselItem, key) => {
        const CardIcon = createBouqsCdnIcon(
          item.icon?.replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) =>
            chr.toUpperCase()
          ) ?? ''
        );

        return (
          <LinkBox
            as="li"
            key={`carousel-card-${key}`}
            display="block"
            background="white"
            px="4"
            py="10"
            mx="3"
            my="8"
            maxWidth="80"
            width={proportionalWidth}
            minWidth="60"
            flexShrink={1}
            borderRadius="1.5"
            boxShadow="lg">
            <LinkOverlay
              href={data.link_url}
              color="text"
              _hover={{
                textDecoration: 'none',
              }}>
              <Box
                borderRadius="50%"
                background={item.background}
                w="28"
                h="28"
                mb="10"
                mx="auto">
                <CardIcon w="100%" h="100%" color="primary" p="7" />
              </Box>
              <Heading
                as="p"
                size="xs"
                mb="4"
                color={['primary', null, null, 'text']}>
                {item.heading}
              </Heading>
              <Text size="md">{item.text}</Text>
            </LinkOverlay>
          </LinkBox>
        );
      });
  }, [data]);

  return (
    <Box
      className="cards-carousel-block"
      py="20"
      my="14"
      textAlign="center"
      backgroundImage={data?.media}
      backgroundPosition="top center"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      __css={styles}>
      <LinkBox py="4">
        <LinkOverlay
          href={data.link_url}
          color="text"
          _hover={{
            textDecoration: 'none',
          }}>
          <Heading as="p" size="xl" mb="4">
            {data?.heading}
          </Heading>
          <Heading mb="4" as="p" size="sm">
            {data?.subheading}
          </Heading>
          <Text mb="4" size="md" maxWidth="2xl" margin="auto">
            {data?.description}
          </Text>
        </LinkOverlay>
      </LinkBox>
      <HiddenScrollbarsContainer overflow="scroll" w="100%">
        <List
          mb="8"
          py="8"
          px="3"
          margin="auto"
          display="flex"
          justifyContent="flex-start"
          alignContent="stretch"
          width="min-content"
          listStyleType="none">
          {items}
        </List>
      </HiddenScrollbarsContainer>
      <Button
        as="a"
        href={data.link_url}
        variant="solid"
        width="56"
        background="primary"
        color="white"
        fontSize="md"
        letterSpacing="wider"
        fontWeight="bold"
        px="8"
        py="3"
        borderRadius="md">
        {data.link_text}
      </Button>
    </Box>
  );
};

export default CardsCarouselBlock;
