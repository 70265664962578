import { Skeleton, SkeletonProps } from '@thebouqs/ui';

export const SuperHeroMediaSkeleton = (props: SkeletonProps): JSX.Element => (
  <Skeleton
    width="100%"
    height="100%"
    position="absolute"
    top={0}
    left={0}
    startColor="lightGray"
    endColor="lightestGray"
    {...props}
  />
);
