import React, { useCallback, useState } from 'react';
import { useLazyImage } from '@/hooks/useLazyImage';
import { useObserver } from '@/hooks/useObserver';
import TranslatedText from '@/components/TranslatedText';
import { makeImageSourceSet } from '@/utils/imageUtilities';
import isBrowser from '@/utils/isBrowser';
import { SF_URL } from '@/constants/env';
import ProductSummaryMediaContainer from './ProductSummaryMedia.styles';
import { ProductSummaryMediaProps } from './ProductSummaryMedia.d';

const loadingPicture = (
  <div data-loading="true" className="product-summary__media-image">
    <div />
  </div>
);

export default function ProductSummaryMedia(
  props: ProductSummaryMediaProps
): JSX.Element {
  const { primary_image, purchasable, promotional_badge, renderImage } = props;

  const srcSetArr = makeImageSourceSet(primary_image.path, {
    aspectRatio: 'squared',
    fit: 'cover',
    bpConfig: {
      SM: {
        quality: 50,
        size: 212,
      },
      MD: {
        quality: 50,
        size: 344,
      },
      LG: {
        quality: 100,
        size: 454,
      },
      XL: {
        size: 416,
      },
    },
  });

  const srcSetBadges = makeImageSourceSet(promotional_badge?.path, {
    fit: 'scale-down',
    bpConfig: {
      SM: {
        quality: 50,
        size: 112,
      },
      MD: {
        quality: 50,
        size: 176,
      },
      LG: {
        quality: 100,
        size: 192,
      },
    },
  });

  const srcSet = srcSetArr.join();

  const {
    isLoading: isImageLoading,
    startDownLoad,
    onLoad,
  } = useLazyImage({
    srcSet,
    autoLoad: renderImage,
  });

  const [instance, setInstance] = useState(null);
  const [intersects, setIntersects] = useState(renderImage ?? null);
  const observer = useObserver(instance);

  const observed = useCallback(
    node => {
      if (node && !instance) {
        setInstance(node);
        node.addEventListener('intersects', () => {
          setIntersects(true);
          /* setting instance will trigger a rerender before useObserver returns
           *  the observer. handle such case
           */
          observer?.unobserve(node);

          // now dispatch onLoad() if the image is readily available (loaded from cache)
          const img = node.getElementsByTagName('img')[0];

          if (!img?.complete) {
            startDownLoad();
          }
        });
      }
    },
    [instance, observer, startDownLoad]
  );

  const pictureNode = (
    <img
      loading="lazy"
      data-loading={isImageLoading}
      onLoad={onLoad}
      width="100%"
      height="100%"
      src={`${SF_URL}${primary_image.path}`}
      srcSet={srcSet}
      sizes="(max-width: 479px) 212px,
      (min-width: 480px and max-width: 767px) 344px,
      (min-width: 768px and max-width: 991px) 454px,
      (min-width: 992px) 416px"
      alt={primary_image.alt_text}
    />
  );

  return (
    <ProductSummaryMediaContainer
      className="product-summary__media"
      ref={observed}>
      {isBrowser()
        ? intersects
          ? pictureNode
          : loadingPicture
        : renderImage
        ? pictureNode
        : loadingPicture}
      {/*TODO: move text strings to single translation (RUI-28) */}
      {!purchasable && (
        <TranslatedText
          fontSize="sm"
          textTransform="uppercase"
          color="white"
          fontWeight="600"
          letterSpacing="widest"
          textAlign="center"
          backgroundColor={['black50', 'black30']}
          className="product-summary__media__unavailable"
          t="catalog.productSummary.soldOut"
        />
      )}
      {promotional_badge?.path && (
        <img
          width="auto"
          height="auto"
          loading="lazy"
          className="product-summary__media-promotional-badge"
          src={`${SF_URL}${promotional_badge.path}`}
          alt={promotional_badge.alt_text}
          srcSet={srcSetBadges?.length ? srcSetBadges.join() : null}
          sizes="(max-width: 767px) 112px,
          (min-width: 768px and max-width: 991px) 176px,
          (min-width: 992px) 192px"
        />
      )}
    </ProductSummaryMediaContainer>
  );
}
