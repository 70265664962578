import React from 'react';
import { BouqsLogo } from '@thebouqs/icons';
import useTranslation from '@/hooks/useTranslation';
import { NavLogoProps } from './NavLogo.d';
import NavLogoContainer from './NavLogo.styles';

const NavLogo = ({ isDrawer = false }: NavLogoProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <NavLogoContainer
      cursor="pointer"
      as="a"
      href="/"
      aria-label={t('common.goToHome')}
      className="page-header__logo"
      px="sm"
      py="none"
      zIndex={[null, null, null, 1]}
      position={[null, null, null, 'absolute']}
      left="50%"
      margin="auto"
      marginLeft={[null, null, null, '-24']}
      textAlign="center">
      <BouqsLogo color="primary" h="auto" w={isDrawer ? '20' : '24'} />
    </NavLogoContainer>
  );
};

export default NavLogo;
