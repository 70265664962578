import styled from '@emotion/styled';
import { Box } from '@thebouqs/ui';

export const HiddenScrollbarsContainer = styled(Box)`
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;
