import React, { useCallback } from 'react';
import { Box, Heading } from '@thebouqs/ui';
import useAnalytics from '@/hooks/useAnalytics';
import { getCheapestVariant } from '@/utils/productVariantAccessors';
import { SegmentTrackProperties } from '@/store/analytics/AnalyticsProvider.d';
import useFirstPaint from '@/hooks/useFirstPaint';
import ProductSummaryContainer from './ProductSummary.styles';
import { ProductSummaryProps } from './ProductSummary.d';
import useUser from '@/hooks/useUser';
import ProductSummaryPricing from './ProductSummaryPricing';
import ProductSummaryMessage from './ProductSummaryMessage';
import ProductSummaryMedia from './ProductSummaryMedia';
import ProductSummaryAvailability from './ProductSummaryAvailability';

const ProductSummary = ({
  categoryPath,
  product,
  productIndex,
  sort,
  blockCategory,
  displayConfig = {
    showPromotionalBadge: true,
  },
  activeFilters,
}: ProductSummaryProps): JSX.Element => {
  const {
    purchasable,
    discountable,
    on_sale,
    multipriced,
    category,
    name,
    id,
    promotional_badge,
    path,
    variants,
    product_type,
    default_vase_id,
    customization,
    first_available_date,
  } = product;
  const {
    is_subscriber: isSubscriber,
    subscription_activation_used: isSubscriptionActivationUsed,
  } = useUser();
  const { track } = useAnalytics();
  // original variant prices are used for catalog
  const lowestVariant = getCheapestVariant(product);
  const lowestProductPrices = lowestVariant?.prices;
  const lowestProductPricesFull = lowestProductPrices?.full || '0.00';
  const isPrepaid = customization === 'prepaid';
  // subscriber discount only shown for users who didn't use the subscription bonus and discountable products
  const showSubscriberDiscount =
    !isSubscriber &&
    !isSubscriptionActivationUsed &&
    discountable &&
    !isPrepaid;
  // products on sale and products with only one price do not have messages
  const showMessage = discountable || (!on_sale && multipriced);
  // server side render image if is within the first three
  const isFirstThree = productIndex <= 2;
  const isFirstPaint = useFirstPaint();
  const renderImage = isFirstPaint && isFirstThree;

  // TODO: Use NextJs Link component once PDPs are built in Nextjs
  const productOnClick = useCallback(() => {
    const productPrice = parseFloat(lowestProductPricesFull).toFixed(2);
    const productClickedTrackingDataObj: SegmentTrackProperties = {
      category: `${blockCategory ?? category}`,
      label: name,
      name: name,
      content_group: 'category page',
      path: `/${categoryPath}`,
      product_category: category,
      url: `${window.location.hostname}/${categoryPath}`,
      is_subscriber: isSubscriber || false,
      position: productIndex,
      price: parseFloat(productPrice),
      product_id: id,
      sku: lowestVariant?.sku,
      filters: activeFilters,
      //Sort and Filter exist in [category_slug] they were passed down in order to add them there
      // they went from [category_slug] -> ProductGrid.tsx ->ProductSummary
      sort: sort,
      vase: default_vase_id,
    };

    if (blockCategory) {
      track('Best Sellers Product List Content Block Click', {
        category: 'Home Page',
        label: name,
        is_subscriber: isSubscriber || false,
      });
    } else {
      track('Product Clicked', productClickedTrackingDataObj);
    }
  }, [
    track,
    blockCategory,
    category,
    categoryPath,
    default_vase_id,
    id,
    lowestProductPricesFull,
    lowestVariant?.sku,
    name,
    productIndex,
    activeFilters,
    sort,
  ]);

  return product ? (
    <ProductSummaryContainer
      position="relative"
      zIndex="base"
      display="inline-block"
      width="100%"
      cursor="pointer"
      _hover={{
        textDecoration: 'none',
      }}
      as="a"
      letterSpacing="widest"
      className="product-summary"
      onClick={productOnClick}
      href={path}
      isSale={on_sale}
      data-product-is-purchaseable={purchasable}
      data-product-has-vase={!!default_vase_id}
      data-product-variant-count={variants?.length}
      data-product-type={product_type}>
      <Box className="product-summary__media" mb="sm">
        <ProductSummaryMedia
          primary_image={product.primary_image}
          purchasable={purchasable}
          promotional_badge={
            displayConfig.showPromotionalBadge ? promotional_badge : null
          }
          renderImage={renderImage}
        />
      </Box>
      <div className="product-summary__info">
        <Heading
          paddingRight={on_sale ? ['20', null, null, '3xl'] : 'xl'}
          color="text"
          as="h3"
          size="xs"
          mb={[1, null, 1]}
          fontSize={['sm', null, 'md']}
          className="product-summary__name">
          {product.name}
        </Heading>
        {purchasable && (
          <ProductSummaryPricing
            color="text"
            onSale={on_sale}
            multipriced={multipriced}
            showMessage={showMessage}
            prices={lowestProductPrices}
            isSubscriber={isSubscriber}
            isSubscriptionActivationUsed={isSubscriptionActivationUsed}
            discountable={discountable}
            isPrepaid={isPrepaid}
          />
        )}
        {showMessage && (
          <ProductSummaryMessage
            onSale={on_sale}
            showSubscriberDiscount={showSubscriberDiscount}
            multipriced={multipriced}
            purchasable={purchasable}
            isPrepaid={isPrepaid}
            prices={lowestProductPrices}
          />
        )}
        <ProductSummaryAvailability date={first_available_date} />
      </div>
    </ProductSummaryContainer>
  ) : null;
};

export const ProductSummarySkeleton = (): JSX.Element => {
  return <Box p="50%" backgroundColor="lightestGray"></Box>;
};

export default ProductSummary;
