import isBrowser from '@/utils/isBrowser';
import { SF_API_V3 } from '@/constants/env';

export function endpointResponseLogging(
  data: Record<string, unknown>,
  path: string
): void {
  if (!isBrowser()) {
    // eslint-disable-next-line no-console
    console.info('[Endpoint Response]:', `${SF_API_V3}${path}`, data);
  }
}
