export const RichTextBlockStyles = {
  '.wysiwyg-text-align-left': {
    textAlign: 'left',
  },

  '.wysiwyg-text-align-center': {
    textAlign: 'center',
  },

  '.wysiwyg-text-align-right': {
    textAlign: 'right',
  },

  '.wysiwyg-font-size-xs--mobile': {
    fontSize: ['xs', 'inherit'],
  },

  '.wysiwyg-font-size-sm--mobile': {
    fontSize: ['sm', 'inherit'],
  },

  '.wysiwyg-font-size-lg--mobile': {
    fontSize: ['lg', 'inherit'],
  },

  '.wysiwyg-font-size-xl--mobile': {
    fontSize: ['xl', 'inherit'],
  },

  '.wysiwyg-font-size-xs--tablet': {
    fontSize: [null, 'xs', 'inherit'],
  },

  '.wysiwyg-font-size-sm--tablet': {
    fontSize: [null, 'sm', 'inherit'],
  },

  '.wysiwyg-font-size-lg--tablet': {
    fontSize: [null, 'lg', 'inherit'],
  },

  '.wysiwyg-font-size-xl--tablet': {
    fontSize: [null, 'xl', 'inherit'],
  },

  '.wysiwyg-font-size-xs--desktop': {
    fontSize: [null, null, 'xs'],
  },

  '.wysiwyg-font-size-sm--desktop': {
    fontSize: [null, null, 'sm'],
  },

  '.wysiwyg-font-size-lg--desktop': {
    fontSize: [null, null, 'lg'],
  },

  '.wysiwyg-font-size-xl--desktop': {
    fontSize: [null, null, 'xl'],
  },
};
