import dynamic from 'next/dynamic';
import { Link } from '@thebouqs/ui';
import { MenuLinkProps } from './MenuLink.d';

const SubMenu = dynamic(() => import('../SubMenu'), { ssr: false });

const MenuLink = ({
  linkContent,
  linkChildren,
  linkPath,
  topLinkIndex,
  isDesktop,
}: MenuLinkProps): JSX.Element => {
  if (isDesktop && linkChildren) {
    return (
      <SubMenu
        topLinkContent={linkContent}
        topLinkIndex={topLinkIndex}
        topLinkPath={linkPath}
        subMenuLinks={linkChildren}
      />
    );
  } else {
    return (
      <Link href={linkPath} fontWeight="semibold">
        {linkContent}
      </Link>
    );
  }
};

export default MenuLink;
