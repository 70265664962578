import { useEffect } from 'react';
import { useRouter } from 'next/router';
import useAnalytics from './useAnalytics';

function usePageView(name: string, controlGroup: string): void {
  const { pathname } = useRouter();
  const { page } = useAnalytics();

  useEffect(() => {
    page(name, { content_group: controlGroup });
  }, [controlGroup, name, page, pathname]);
}

export default usePageView;
