import styled from '@emotion/styled';
import { Box } from '@thebouqs/ui';

export const FullWidthContainer = styled(Box)`
  width: 100vw;
  position: relative;
  right: 50%;
  left: 50%;
  margin-right: -50vw;
  margin-left: -50vw;
`;
