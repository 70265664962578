import color from 'tinycolor2';

/**
 *
 *  Add alpha to a HEX color and convert it to rgba
 * @param hex - hex or some tinycolor2 compatible colors
 * @param alpha - number between 0 and 100
 * @returns
 */
export function convertHexToRgba(hex: string, alpha: number): string {
  const rgb = color(hex);
  const rgba = rgb.setAlpha(alpha / 100);
  return rgba.toRgbString();
}
