import { SystemStyleObject } from '@thebouqs/ui';

export type StyledBlockProps = {
  variant: 'light' | 'dark';
  focusPoint: 'left' | 'right' | 'center';
  contentPosition: 'left' | 'right';
  showDescription: boolean;
  isMobile: boolean;
  className?: string;
  key?: string;
};

const resolveColor = (props: StyledBlockProps) => {
  return props?.variant === 'light' ? 'white' : 'text';
};

const resolveGradientColors = (props: StyledBlockProps) => {
  const { variant } = props;
  let startColor, endColor;

  if (variant === 'dark') {
    startColor = 'whiteAlpha.300';
    endColor = 'whiteAlpha.500';
  } else {
    startColor = 'blackAlpha.300';
    endColor = 'blackAlpha.500';
  }

  return `${startColor} 10%, ${endColor} 100%`;
};

export const CategoryBannerBlockStyles = (
  props: StyledBlockProps
): SystemStyleObject => {
  return {
    height: ['auto', '64'],
    position: 'relative',
    marginBottom: [null, null, 'md'],

    '.category-banner-content-block__img': {
      objectFit: 'cover',
      objectPosition: props?.focusPoint,
      position: 'absolute',
      display: ['none', 'block'],
      zIndex: 'hide',
      width: '100%',
      height: '100%',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      color: 'currentColor',
    },

    '.area-block-content': {
      height: '100%',
      maxWidth: [null, '60%'],
      marginRight: ['auto', props?.contentPosition === 'right' ? '0' : null],
      marginLeft: ['auto', props?.contentPosition === 'left' ? '0' : null],
      py: ['md', null, 'md'],
      px: ['lg', null, 'xl'],
      flexDirection: 'column',
      justifyContent: 'center',
      color: ['text', resolveColor(props)],
      textAlign: ['center', props?.contentPosition ?? 'left'],
      bgGradient: [
        null,
        `linear(to ${
          props?.contentPosition
        }, transparent 0%, ${resolveGradientColors(props)})`,
      ],
    },

    '.category-banner-content-block__title': {
      mx: ['auto', 'none'],
      color: 'currentColor',
      _after: {
        content: "''",
        display: 'block',
        width: '6',
        height: '1px',
        mt: '5',
        marginRight: ['auto', props?.contentPosition === 'right' ? '0' : null],
        marginLeft: ['auto', props?.contentPosition === 'left' ? '0' : null],
        background: ['text', resolveColor(props)],
      },
    },

    '.category-banner-content-block__description': {
      letterSpacing: 'wide',
      color: 'currentColor',
    },

    '.category-banner-content-block__toggle-description-button': {
      color: 'primary',
      fontSize: 'sm',
      backgroundColor: 'transparent',
      margin: 'auto',
      cursor: 'pointer',
      border: 'none',
      textDecoration: props?.showDescription ? 'underline' : 'initial',
      display: ['inline-block', null, 'none'],
    },

    '.category-banner-content-block__value-add': {
      mt: '5',
      color: 'currentColor',
    },
  };
};
