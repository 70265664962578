import NextLink from 'next/link';

import { Ribbon } from '@thebouqs/ui';
import useAnalytics from '@/hooks/useAnalytics';
import { RibbonBlockProps } from './RibbonBlock.d';
import RibbonBlockSkeleton from './RibbonBlockSkeleton';
import useUser from '@/hooks/useUser';

const NextRibbonBlock = (props: RibbonBlockProps): JSX.Element => {
  const {
    data: { html, link_url, background, color },
    category,
    _key,
  } = props;
  const { track } = useAnalytics();

  const { is_subscriber: isSubscriber } = useUser();

  const ribbonOnClickFn = () => {
    track('Ribbon Content Block Clicked', {
      category: category?.length ? category : 'All',
      label: html,
      is_subscriber: isSubscriber || false,
    });
  };

  return props?.data ? (
    <NextLink href={link_url} key={_key}>
      <Ribbon
        link={link_url}
        backgroundColor={background}
        color={color}
        data-testid={`${category}_${_key}`}
        html={html}
        onClickFn={ribbonOnClickFn}
      />
    </NextLink>
  ) : (
    <RibbonBlockSkeleton />
  );
};

export default NextRibbonBlock;
