/* 
  K.R. : could not get theme from useTheme() to work here, nor tg(key)(props)
  I had to resort to using var(--key-key) ie var(--lineHeights-2xl) in order for
  tests to pass. The tests broke with the former two attempts, only var(...) is working
  without complaints
*/
export const FooterStyles = {
  '.grid': {
    lineHeight: 'base',
    color: 'text',
    display: 'flex',
    flexDirection: ['column', null, null, 'row'],
    justifyContent: 'space-evenly',
    alignItems: 'flex-start',
    fontSize: 'xs',
  },
  '.grid__cell': {
    display: 'flex',
    flexDirection: 'column',
    width: 'auto',
    minWidth: '24',
    h3: {
      py: '2',
      px: '0',
      textAlign: 'left',
      fontWeight: '600',
      textTransform: 'uppercase',
      margin: '0',
      fontSize: 'xs',
    },
  },
  '.page-footer__nav-list': {
    margin: '0',
    marginBottom: '2',
    padding: '0',
  },
  '.page-footer__nav-item': {
    listStyleType: 'none',
    py: '2',
    px: '0',
  },
  a: {
    color: 'inherit',
  },
};
