import { Skeleton } from '@thebouqs/ui';

export const CategoryBannerSkeleton = (): JSX.Element => {
  return (
    <Skeleton
      width="100%"
      height="100%"
      position="absolute"
      top="0"
      left="0"
      startColor="lightGray"
      endColor="lightestGray"
    />
  );
};
