import React from 'react';
import dynamic from 'next/dynamic';
import { List, Skeleton } from '@thebouqs/ui';
import { SFErrorBoundary } from '@/components/ErrorBoundary';
import MenuItemErrorFallback from '@/components/TopNav/MenuItemErrorFallback';
import { MegaMenuStyles } from './MegaMenu.styles';
import { MegaMenuProps } from './MegaMenu.d';

const TopMenu = dynamic(import('./TopMenu'), {
  loading: () => <Skeleton height="16" />,
  ssr: false,
});

const MegaMenu = ({ isDesktop }: MegaMenuProps): JSX.Element => {
  return (
    <List
      className="mega-menu"
      sx={MegaMenuStyles}
      position="relative"
      // Using display instead of conditional rendering to prevent CLS
      // since useBreakpoint is undefined server-side
      display={['none', null, null, 'inline-flex']}
      justifyContent="center"
      alignItems="center"
      height="16"
      margin="none">
      {isDesktop && (
        <SFErrorBoundary
          fallbackComponent={<MenuItemErrorFallback />}
          targetComponent="MegaMenu">
          <TopMenu />
        </SFErrorBoundary>
      )}
    </List>
  );
};

export default MegaMenu;
