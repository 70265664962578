import useServiceWorker from '@/hooks/useServiceWorker';
import useTranslation from '@/hooks/useTranslation';
import React from 'react';
import TranslatedText from '@/components/TranslatedText';
import { HStack, VStack, Modal, ModalCloseButton, Button } from '@thebouqs/ui';
import { commonInt } from '@/i18n/translators';
import createTranslator from '@/i18n/utils/createTranslator';

const swInt = createTranslator('serviceWorker');

export default function ServiceWorkerManager(): JSX.Element {
  const { onNewVersion, onLogInAgain } = useServiceWorker();

  const { showPrompt, deny, confirm } = onNewVersion;
  const OnLogInConfirm = onLogInAgain.confirm;
  const OnLogInDeny = onLogInAgain.deny;
  const OnLogInShowPrompt = onLogInAgain.showPrompt;

  const { t } = useTranslation();

  const newVersionTitle = t(swInt('newVersionTitle'));
  const onLoginTitle = t(swInt('onLogInAgainTitle'));

  const describedById = 'sw-new-version-description';
  const describedByOnLogIn = 'sw-on-login-description';

  return (
    <>
      <Modal
        open={showPrompt}
        label="prompt app update"
        callback={deny}
        aria-describedby={describedById}
        size="sm"
        CloseButton={
          <ModalCloseButton label="close-prompt" variant="normal" size="sm" />
        }>
        <VStack p="md" alignItems="space-between">
          <TranslatedText
            aria-hidden
            as="header"
            fontSize="xl"
            fontWeight="bold">
            {newVersionTitle}
          </TranslatedText>
          <TranslatedText
            fontSize="lg"
            t={swInt('newVersionDescription')}
            id={describedById}
          />
          <HStack alignSelf="flex-end" mt="auto">
            <Button variant="outline" onClick={deny} fontSize="xl" p="sm">
              {t(swInt('newVersionSkip'))}
            </Button>
            <Button onClick={confirm} fontSize="xl" p="sm">
              {t(commonInt('update'))}
            </Button>
          </HStack>
        </VStack>
      </Modal>
      <Modal
        open={OnLogInShowPrompt}
        callback={OnLogInDeny}
        label="log in again"
        size="sm"
        CloseButton={
          <ModalCloseButton label="close-prompt" variant="normal" size="sm" />
        }>
        <VStack p="md" alignItems="space-between">
          <TranslatedText
            aria-hidden
            as="header"
            fontSize="xl"
            fontWeight="bold">
            {onLoginTitle}
          </TranslatedText>
          <TranslatedText
            fontSize="lg"
            t={swInt('newVersionDescription')}
            id={describedByOnLogIn}
          />
          <HStack alignSelf="flex-end" mt="auto">
            <Button
              variant="secondary"
              onClick={OnLogInDeny}
              fontSize="xl"
              p="sm">
              {t(commonInt('cancel'))}
            </Button>
            <Button onClick={OnLogInConfirm} fontSize="xl" p="sm">
              {t(commonInt('login'))}
            </Button>
          </HStack>
        </VStack>
      </Modal>
    </>
  );
}
