import renderContentAreaBlocks from './ContentAreaBlocks';
import useStatic from '@/hooks/useStatic';
import { Box } from '@thebouqs/ui';

const ContentArea2 = (): JSX.Element => {
  const {
    contentAreas,
    pageData: { name },
  } = useStatic();

  const data = contentAreas?.area_2;

  const blocks = renderContentAreaBlocks({ data }, name, 'area-2');

  return <Box className="content-area content-area--2">{blocks}</Box>;
};

export default ContentArea2;
