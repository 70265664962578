export const MegaMenuStyles = {
  '> li': {
    marginX: [null, null, null, '2', '4'],
  },
  '.mega-menu__item': {
    display: 'block',
    paddingY: '1',
    fontWeight: 'semibold',

    '&--top': {
      /* ensure there's enough padding below the link so mouseOut event 
         isn't triggered too early when slowly mousing into the popover content */
      paddingBottom: '2',
    },

    /* padding, z-index, and margin added so the link overlays the arrow
       the overlay helps triggers the mouseOver and mouseOut events on the arrow as well as the link */
    '&--trigger': {
      position: 'relative',
      display: 'inline-block',
      paddingRight: '6',

      '&__button': {
        minWidth: '0',
        marginLeft: '-6',
        zIndex: 'hide',
      },
    },

    '&--placeholder, &--heading': {
      color: 'darkerGray',
    },

    '&--heading': {
      fontSize: 'md',
    },
  },
};
