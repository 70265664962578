import { Text as BouqsText } from '@thebouqs/ui';
import { TextProps, TObject } from './Text.d';
import useTranslation from '@/hooks/useTranslation';

export default function TranslatedText(props: TextProps): JSX.Element {
  const { t } = useTranslation();
  const { t: translateProp, ...textProps } = props;
  let translation = null;

  if (translateProp) {
    if (
      Object.prototype.hasOwnProperty.call(translateProp, 'id') &&
      Object.prototype.hasOwnProperty.call(translateProp, 'values')
    ) {
      const translateObj = translateProp as TObject;
      translation = t(translateObj.id, translateObj.values);
    } else {
      translation = t(translateProp as string);
    }
  }

  if (translation) {
    return <BouqsText {...textProps}>{translation}</BouqsText>;
  }

  return <BouqsText {...textProps} />;
}
