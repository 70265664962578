import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import useUser from '@/hooks/useUser';
import isBrowser from '@/utils/isBrowser';
import { EXIT_INTENT_KEY } from './ExitIntentBlock.constants';

import { ExitIntentBlockModal } from './ExitIntentBlock.components/ExitIntentBlockModal';

export interface ExitIntentBlockProps {
  data: {
    header: string;
    subheader: string;
    button_text: string;
    cancel_button_text: string;
    media: string;
  };
}

export function ExitIntentBlock(
  props: ExitIntentBlockProps,
  key: string
): JSX.Element {
  const [wasShown, setWasShown] = useState(true);

  const { logged_in } = useUser();
  const doNotRender = logged_in || wasShown;

  useEffect(() => {
    const { sessionStorage } = window;
    const showModal = sessionStorage.getItem(EXIT_INTENT_KEY);

    if (!showModal) {
      setWasShown(false);
    }
  }, []);

  if (!isBrowser() || doNotRender) {
    return null;
  }

  return createPortal(
    <ExitIntentBlockModal
      key={key}
      setWasShown={setWasShown}
      {...props.data}
    />,
    document.getElementById('exit-intent-block-root')
  );
}
