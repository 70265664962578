import { createIcon } from '@thebouqs/icons';

const FacebookIcon = createIcon({
  displayName: 'FacebookIcon',
  viewBox: '0 0 32 32',
  path: (
    <path
      fill="currentColor"
      d="M16 0.32c-8.672 0-15.68 7.008-15.68 15.68s7.008 15.68 15.68 15.68 15.68-7.008 15.68-15.68c0-8.672-7.008-15.68-15.68-15.68zM20.064 15.872l-2.56 0.32v9.728h-3.104v-9.92h-2.56v-3.232h2.24l-0.064-1.856c0-2.656 0.672-4.192 3.776-4.192h3.008v2.88h-2.048c-1.216 0-1.28 0.448-1.28 1.28v1.6h2.912l-0.32 3.392z"
    />
  ),
});

export default FacebookIcon;
