import { floral, forwardRef } from '@thebouqs/ui';
import { InputProps } from './Input.d';

export const Input = forwardRef<InputProps, 'input'>((props, ref) => {
  const { colorScheme, ...inputProps } = props;

  const styleProps = getColorStyle(colorScheme);

  return (
    <floral.input
      ref={ref}
      py="2"
      px="2.5"
      border="1px solid"
      borderColor="text"
      __css={styleProps}
      {...inputProps}
    />
  );
});

const getColorStyle = (c: string) => {
  if (c === 'red') {
    const RED = `${c}.600`;
    return {
      color: RED,
      borderColor: RED,
      '&:focus': {
        borderColor: RED,
        outline: '2px solid',
        outlineColor: RED,
      },
    };
  }

  return {
    borderColor: 'text',
    outline: '2px solid',
    outlineColor: 'text',
  };
};
