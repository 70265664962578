import { useIntl } from 'react-intl';
import { UseTranslationReturns } from './useTranslation.d';

// MESSAGE: this is an early abstraction, it could probably lack a wider implementation
/**
 *
 * @description An less verbose version of `useIntl()`
 */
export default function useTranslation(): UseTranslationReturns {
  const { formatMessage } = useIntl();

  function t(id: string, values?: Record<string, unknown>): string {
    return formatMessage({ id }, values) as string;
  }

  return { t };
}
