import renderContentAreaBlocks from './ContentAreaBlocks';
import useStatic from '@/hooks/useStatic';

const HeaderPromo = (): JSX.Element => {
  const {
    contentAreas,
    pageData: { name },
  } = useStatic();

  const dataObj = contentAreas?.header_promo ?? [];

  /*
    Needed to wrap the response in an object because ContentAreaBlocks
    checks for the data to be an array of objects which it then applies
    a reduce function on.
   */
  const data = Array.isArray(dataObj) ? dataObj : [dataObj];

  const blocks = renderContentAreaBlocks({ data }, name, 'header-promo');

  return <> {blocks.length ? blocks : null} </>;
};

export default HeaderPromo;
