import { Skeleton, SkeletonProps } from '@thebouqs/ui';
import styled from '@emotion/styled';

const RibbonSkeleton = styled(Skeleton)`
  height: ${({ theme }) => theme.sizes['20']};
`;

export const RibbonBlockSkeleton = (props: SkeletonProps): JSX.Element => (
  <RibbonSkeleton
    width="100%"
    startColor="lightGray"
    endColor="lightestGray"
    {...props}
  />
);

export default RibbonBlockSkeleton;
