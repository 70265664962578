import { useEffect, useState } from 'react';
import useRafState from '../utils/useRafState';
import { State, ScrollState } from './useScrolling.d';
import isBrowser from '@/utils/isBrowser';

const isClient = isBrowser();
const initialXOffset = isClient ? window.pageXOffset : 0;
const initialYOffset = isClient ? window.pageYOffset : 0;

export function useScrolling(): ScrollState {
  const [scrollDirection, setScrollDirection] = useState(null);
  const [prevOffset, setPrevOffset] = useState(0);
  const [state, setState] = useRafState<State>({
    isScrolling: false,
    x: initialXOffset,
    y: initialYOffset,
  });

  useEffect(() => {
    const { scrollY } = window;
    if (scrollY === 0) {
      setScrollDirection(null);
    }
    if (scrollY > prevOffset) {
      setScrollDirection('down');
    } else if (scrollY < prevOffset) {
      setScrollDirection('up');
    }
    setPrevOffset(scrollY);
  });

  useEffect(() => {
    if (isClient) {
      let scrollingTimeout;

      const handleScrollEnd = () => {
        setState({
          isScrolling: false,
          x: window.pageXOffset,
          y: window.pageYOffset,
        });
      };

      const handleScroll = () => {
        setState({
          isScrolling: true,
          x: window.pageXOffset,
          y: window.pageYOffset,
        });
        clearTimeout(scrollingTimeout);
        scrollingTimeout = setTimeout(() => handleScrollEnd(), 250);
      };

      window.addEventListener('scroll', handleScroll, false);
      return () => {
        window.removeEventListener('scroll', handleScroll, false);
      };
    }
  }, []);

  return {
    scrollDirection,
    ...state,
  };
}
