import { BasketProps } from './BasketIcon.d';
import { createIcon, Flex, Text } from '@thebouqs/ui';

// TODO: move this to bouqs-ui
const BasketIconTag = createIcon({
  displayName: 'BasketIcon',
  viewBox: '0 0 26 20',
  path: (
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M24.916 5.41h-4.477l-4.9-5.06c-.38-.401-.923-.461-1.224-.16l-.18.2c-.302.282-.221.764.14 1.165l3.754 3.855H8.131l3.755-3.855c.381-.401.441-.903.14-1.185l-.18-.16c-.302-.281-.844-.261-1.225.14L5.722 5.41H1.124C.502 5.41 0 5.772 0 6.414c0 .643.502 1.004 1.124 1.004h.14L2.892 17.74c0 .964.783 1.928 1.747 1.928h16.845c.963 0 1.485-1.044 1.746-1.928l1.627-10.32h.02c.622 0 1.124-.362 1.124-1.005 0-.642-.462-1.004-1.084-1.004z"
    />
  ),
});

const BasketIcon = (props: BasketProps): JSX.Element => {
  const { quantity = 0, ...basketProps } = props;

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      position="relative"
      height="100%">
      <BasketIconTag {...basketProps} color={quantity > 0 ? 'primary' : null} />
      <Text
        position="absolute"
        left="51%"
        top="59%"
        transform="translate(-50%, -50%)"
        className="numItems"
        color="white"
        fontSize="xs"
        fontWeight="600">
        {quantity}
      </Text>
    </Flex>
  );
};

export default BasketIcon;
