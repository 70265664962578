import { createIcon } from '@thebouqs/icons';

const CalendarIcon = createIcon({
  displayName: 'CalendarIcon',
  viewBox: '0 0 51 48',
  path: (
    <g
      id="Desktop-UI"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd">
      <g
        id="1.3.Subs-Dashboard-D"
        transform="translate(-713.000000, -419.000000)"
        fill="currentColor"
        fillRule="nonzero">
        <g
          id="Your-Next-Delivery-"
          transform="translate(170.000000, 256.000000)">
          <g id="Next-Delivery-Info">
            <g id="Delivery-Date" transform="translate(543.000000, 161.000000)">
              <g id="calendar-icon" transform="translate(0.000000, 2.039216)">
                <path
                  d="M6.47060803,0 L6.47060803,3.57351516 L0,3.57351516 L0,38.9452585 L23.886824,38.9452585 C26.0501225,44.538838 31.5201069,48.140221 37.5048854,47.9112538 C43.4896639,47.6822866 48.6694085,43.6734635 50.4003663,37.9308965 C52.1313242,32.1883295 50.0317658,25.9784315 45.1733102,22.4707479 L45.1733102,3.63408322 L38.7027022,3.63408322 L38.7027022,0 L37.4932428,0 L37.4932428,3.57351516 L7.68006747,3.57351516 L7.68006747,0 L6.47060803,0 Z M49.8297291,33.857542 C49.8297291,40.9412124 44.1020633,46.6867254 37.0295216,46.6978268 C29.95698,46.7088961 24.2114667,40.9813658 24.1891875,33.8977303 C24.1670376,26.8140948 29.8765144,21.0505281 36.948986,21.0171146 C44.0392746,21.0171146 49.7964414,26.7561836 49.8297291,33.857542 Z M37.5537157,4.78487624 L37.5537157,7.75271086 L38.7631752,7.75271086 L38.7631752,4.84544429 L44.0243238,4.84544429 L44.0243238,21.6833632 C39.138647,18.8601135 33.0399177,19.2418834 28.5432429,22.6524521 L28.5432429,22.1679076 L25.1567564,22.1679076 L25.1567564,23.3792687 L27.6361483,23.3792687 C23.6083166,26.9645104 21.9866064,32.5434905 23.4635132,37.7338974 L1.20945944,37.7338974 L1.20945944,4.84544429 L6.47060803,4.84544429 L6.47060803,7.81327892 L7.68006747,7.81327892 L7.68006747,4.84544429 L37.5537157,4.78487624 Z"
                  id="Shape"></path>
                <rect
                  id="Rectangle"
                  x="17"
                  y="15.2941176"
                  width="3"
                  height="1.01960784"></rect>
                <rect
                  id="Rectangle"
                  x="9"
                  y="15.2941176"
                  width="3"
                  height="1.01960784"></rect>
                <rect
                  id="Rectangle"
                  x="26"
                  y="15.2941176"
                  width="3"
                  height="1.01960784"></rect>
                <rect
                  id="Rectangle"
                  x="34"
                  y="15.2941176"
                  width="3"
                  height="1.01960784"></rect>
                <rect
                  id="Rectangle"
                  x="17"
                  y="22.4313725"
                  width="3"
                  height="1.01960784"></rect>
                <rect
                  id="Rectangle"
                  x="9"
                  y="22.4313725"
                  width="3"
                  height="1.01960784"></rect>
                <rect
                  id="Rectangle"
                  x="17"
                  y="29.5686275"
                  width="3"
                  height="1.01960784"></rect>
                <rect
                  id="Rectangle"
                  x="9"
                  y="29.5686275"
                  width="3"
                  height="1.01960784"></rect>
                <polygon
                  id="Path"
                  points="37.2389381 27.5294118 36 27.5294118 36 36.7058824 43 36.7058824 43 35.4224599 37.2389381 35.4224599"></polygon>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  ),
});

export default CalendarIcon;
