import { useEffect, useRef, useState } from 'react';
import {
  LazyImageHook,
  LazyImageStatus,
  UseLazyImageProps,
  XOR,
} from './useLazyImage.d';
import { downloadStatus } from './constants';

const noop = () => null;

/**
 *
 * Provide a `src` or `srcset` to start an image lazy loading
 * @returns
 */
export function useLazyImage(
  props: UseLazyImageProps & XOR<{ src: string }, { srcSet: string }>
): LazyImageHook {
  const { autoLoad = true } = props;
  const src = props?.src;
  const srcSet = props?.srcSet;

  const srcType = srcSet ? 'srcset' : 'src';

  const imgRef = useRef(null);

  const [imageDownloadStatus, setImageDownloadStatus] =
    useState<LazyImageStatus>(() => {
      if (autoLoad) {
        return 'OK';
      }
      return 'LOADING';
    });

  const onLoad = () => {
    setImageDownloadStatus('OK');
  };

  function startDownload() {
    const image = document.createElement('img');

    if (srcType === 'src') {
      image.src = src;
    } else {
      image.srcset = srcSet;
    }

    imgRef.current = image;
  }

  useEffect(() => {
    if (autoLoad) {
      startDownload();
    }

    return () => {
      if (imgRef.current) {
        imgRef.current.removeEventListener('load', onLoad, true);
      }
      imgRef.current = null;
    };
  }, [autoLoad]);

  return {
    onLoad,
    status: imageDownloadStatus,
    isOk: imageDownloadStatus === downloadStatus.OK,
    isLoading: imageDownloadStatus === downloadStatus.LOADING,
    hasError: imageDownloadStatus === downloadStatus.ERROR,
    startDownLoad: autoLoad ? noop : startDownload,
  };
}
