const typeEnforcer = {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  boolean: (value: any): boolean => {
    if (typeof value === 'number') {
      return Boolean(value);
    }
    if (typeof value === 'string') {
      const stringifiedBoolean = {
        true: true,
        false: false,
      };

      const booleanString = stringifiedBoolean[value];

      if (typeof booleanString === 'boolean') {
        return booleanString;
      }
      return false;
    }
    return Boolean(value);
  },
};

export default typeEnforcer;
