import React from 'react';
import { Flex } from '@thebouqs/ui';
import useBreakpoint from '@/hooks/useBreakpoint';
import NavHeader from './NavHeader';
import MegaMenu from './MegaMenu';
import { TopNavStyles } from './TopNav.styles';

const TopNav = (): JSX.Element => {
  // Intentionally leaving defaultBreakpoint undefined to use client-side dynamic imports per breakpoint
  const { isDesktop, isMobileTablet } = useBreakpoint();

  return (
    <Flex as="nav" id="nav" sx={TopNavStyles}>
      <NavHeader isDesktop={isDesktop} isMobileTablet={isMobileTablet} />
      <MegaMenu isDesktop={isDesktop} />
    </Flex>
  );
};

export default TopNav;
