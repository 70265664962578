import { Flex } from '@thebouqs/ui';
import TranslatedText from '@/components/TranslatedText';
import {
  InstagramIcon,
  TwitterIcon,
  FacebookIcon,
  PinterestIcon,
} from '@/components/Icons';
import { IconLink } from './SocialLinksCopyright.styles';
import useTranslation from '@/hooks/useTranslation';
import { SOCIALLINKS } from '@/constants/socialLinks';

export default function SocialLinksCopyright(): JSX.Element {
  const { t } = useTranslation();
  const date = new Date();
  const year = date.getFullYear().toString();

  return (
    <>
      <Flex
        id="footer-nav"
        alignItems="center"
        justifyContent="space-between"
        width="40"
        mx="auto">
        <IconLink
          href={SOCIALLINKS.twitter}
          target="_blank"
          aria-label={`${t('common.followOn')} Twitter`}
          title={`${t('common.followOn')} Twitter`}
          rel="nofollow"
          itemProp="sameAs">
          <TwitterIcon fill="darkerGray" fontSize="xl" color="darkerGray" />
          {`${t('common.followOn')} Twitter`}
        </IconLink>
        <IconLink
          href={SOCIALLINKS.facebook}
          target="_blank"
          aria-label={`${t('common.followOn')} Facebook`}
          title={`${t('common.followOn')} Facebook`}
          rel="nofollow"
          itemProp="sameAs">
          <FacebookIcon fill="darkerGray" fontSize="xl" color="darkerGray" />
          {`${t('common.followOn')} Facebook`}
        </IconLink>
        <IconLink
          href={SOCIALLINKS.pinterest}
          target="_blank"
          aria-label={`${t('common.followOn')} Pinterest`}
          title={`${t('common.followOn')} Pinterest`}
          rel="nofollow"
          itemProp="sameAs">
          <PinterestIcon fill="darkerGray" fontSize="xl" color="darkerGray" />
          {`${t('common.followOn')} Pinterest`}
        </IconLink>
        <IconLink
          href={SOCIALLINKS.instagram}
          target="_blank"
          aria-label={`${t('common.followOn')} Instagram`}
          title={`${t('common.followOn')} Instagram`}
          rel="nofollow"
          itemProp="sameAs">
          <InstagramIcon fill="darkerGray" fontSize="xl" color="darkerGray" />
          {`${t('common.followOn')} Instagram`}
        </IconLink>
      </Flex>
      <Flex alignItems="center" justifyContent="center">
        <TranslatedText
          fontSize="sm"
          fontWeight="400"
          m="sm"
          color="darkerGray"
          textTransform="uppercase">
          Copyright {year}, The Bouqs Company
        </TranslatedText>
      </Flex>
    </>
  );
}
