import isBrowser from '@/utils/isBrowser';
import { useEffect, useState } from 'react';
import { WorkboxWindow, UseServiceWorkerReturn } from './useServiceWorker.d';
import { Workbox } from 'workbox-window';
import { SERVICE_WORKER_ON, SF_URL } from '@/constants/env';
import useCookies from '../useCookies';
import typeEnforcer from '@/lib/typeEnforcer';

//  Only overriding window here
declare let window: WorkboxWindow;

const noop = () => null;

type OnNewVersionProps = UseServiceWorkerReturn['onNewVersion'];
type OnLogInAgain = UseServiceWorkerReturn['onLogInAgain'];

const onNewVersionInitialState: OnNewVersionProps = {
  showPrompt: false,
  confirm: noop,
  deny: noop,
};

const onLogInAgainInitialState: OnLogInAgain = {
  showPrompt: false,
  confirm: noop,
  deny: noop,
};

export function useServiceWorker(): UseServiceWorkerReturn {
  const { cookies } = useCookies();
  const swPromptOn = typeEnforcer.boolean(cookies?._bouqs_show_sw_prompt);
  const logInAgainPromptOn = typeEnforcer.boolean(
    cookies?._bouqs_show_login_prompt
  );
  const [newVersionModal, setNewVersionModal] = useState(
    onNewVersionInitialState
  );

  const [onLogInAgain, setOnLogInAgain] = useState(onLogInAgainInitialState);

  const resetNewVersionModal = () => {
    setNewVersionModal(onNewVersionInitialState);
  };

  useEffect(() => {
    if (isBrowser() && 'serviceWorker' in navigator && SERVICE_WORKER_ON) {
      // window.workbox is part of next-pwa
      // But currently it won't work because our SW is inside `/flowers/sw.js`
      // Rather than being in `/sw.js`
      // Once we have control over `/` in staging and production we'll need to remove
      // The code bellow and use `window.workbox` instead
      const wb = new Workbox('/flowers/sw.js');

      wb.addEventListener('controlling', () => {
        // eslint-disable-next-line no-console
        console.log('[SW] RELOADING PAGE');
        window.location.reload();
      });

      wb.addEventListener('waiting', () => {
        if (swPromptOn) {
          setNewVersionModal({
            showPrompt: true,
            confirm: () => {
              wb.messageSkipWaiting();
              // eslint-disable-next-line no-console
              console.log('[SW] CONFIRM');
            },
            deny: resetNewVersionModal,
          });
        } else {
          wb.messageSkipWaiting();
        }
      });

      navigator.serviceWorker.addEventListener('message', event => {
        const { command, msg } = event.data;

        if (command === '@sw/logInAgain') {
          if (msg === true) {
            if (logInAgainPromptOn) {
              setOnLogInAgain({
                showPrompt: true,
                confirm: () => (window.location.href = `${SF_URL}/login`),
                deny: () => setOnLogInAgain(onLogInAgainInitialState),
              });
            }
          }
        }
      });

      window.wb = wb;

      wb.register();
    }
  });

  return {
    onNewVersion: {
      ...newVersionModal,
    },
    onLogInAgain,
  };
}
