import { Product as ProductInterface, ProductVariant } from '@/interfaces';

export const getCheapestVariant = (
  product: ProductInterface
): ProductVariant => {
  return (
    product.variants.find(variant => variant.name == 'Original') ||
    product.variants[0]
  );
};

export const getCheapestVariantFullPrice = (
  product: ProductInterface
): string => {
  const cheapestVariant = getCheapestVariant(product);
  return parseFloat(cheapestVariant?.prices.full).toFixed(2);
};
