import { createIcon } from '@thebouqs/ui';

// TODO: move this to bouqs-ui
const UserIcon = createIcon({
  displayName: 'UserIcon',
  viewBox: '0 0 28 28',
  path: (
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M10.164 15.274a6.482 6.482 0 0 1-1.383-2.653c-.029-.113-.113-.198-.198-.283-.96-1.129-1.553-2.4-1.411-3.924.028-.367.084-.734.592-.847 0-.875-.028-1.779 0-2.654C7.877 2.881 8.752 1.3 10.7.481c1.044-.452 2.174-.536 3.275-.452.705.057 1.411.17 2.06.452 1.807.79 2.71 2.23 2.88 4.15.084.847.056 1.722.056 2.57v.366c.48.085.536.452.565.819.14 1.496-.424 2.767-1.355 3.896a1.798 1.798 0 0 0-.283.508 6.146 6.146 0 0 1-1.326 2.456.748.748 0 0 0 .169.141c1.044.734 2.202 1.186 3.36 1.666.79.339 1.552.65 2.342 1.016 2.23 1.017 3.444 2.88 4.037 5.167.17.649.17 1.327.226 2.004.056.593-.255 1.016-.82 1.186a11 11 0 0 1-1.665.395c-1.665.254-3.359.537-5.024.734-2.484.282-4.997.424-7.509.31-3.387-.14-6.718-.592-10.021-1.185a3.816 3.816 0 0 1-.96-.31c-.451-.198-.677-.565-.705-1.073-.029-1.468.338-2.824 1.016-4.094.762-1.44 1.863-2.513 3.359-3.162 1.185-.508 2.371-1.016 3.528-1.524a8.823 8.823 0 0 0 2.259-1.243z"
    />
  ),
});

export default UserIcon;
