export const TopNavStyles = {
  flexDirection: 'column',
  justifyContent: 'center',
  width: 'full',
  maxWidth: '8xl',
  margin: '0 auto',
  paddingX: ['4', null, null, '6'],
  fontSize: 'sm',
  backgroundColor: 'white',
  transition: 'transform 0.2s ease-out',

  button: {
    border: 'none',
  },

  '.mega-menu, .nav-header-menu, .drawer-menu': {
    'li > a': {
      color: 'text',
      cursor: 'pointer',
      textTransform: 'capitalize',
      textDecorationColor: 'primary',
      textDecorationThickness: '2px',
      textUnderlinePosition: 'under',

      '&:hover, &:active, &:focus': {
        color: 'primary',
      },
    },
  },
  navLoginButton: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '100%',
    letterSpacing: '0.02em',
    color: '#144B3C',
    border: '#144B3C',
    borderRadius: '2px',
    backgroundColor: '#FFFFFF',
    width: '100%',
    height: 'auto',
    padding: '10px 10px 10px',
    borderWidth: '1px 1px',
    borderStyle: 'solid',
    marginBottom: '12px',
    cursor: 'pointer',

    '&:hover': {
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      color: '#4CAF50',
      textDecoration: 'underline',
    },
  },
};
