import isPropValid from '@emotion/is-prop-valid';

export const customShouldForwardProp = (
  whiteListedProps: string[]
): ((prop: string) => boolean) => {
  if (whiteListedProps.length) {
    return prop => isPropValid(prop) || whiteListedProps.includes(prop);
  }
  return prop => isPropValid(prop);
};
