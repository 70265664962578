import { createIcon } from '@thebouqs/icons';

const TwitterIcon = createIcon({
  displayName: 'twitterIcon',
  viewBox: '0 0 32 32',
  path: (
    <path
      fill="currentColor"
      d="M15.936 0.32c-8.672 0-15.68 7.008-15.68 15.68s7.008 15.68 15.68 15.68 15.68-7.008 15.68-15.68c0-8.672-7.040-15.68-15.68-15.68zM23.744 12.48v0.512c0 5.088-3.872 10.976-10.976 10.976-2.176 0-4.224-0.64-5.92-1.728 0.288 0.032 0.608 0.064 0.928 0.064 1.824 0 3.488-0.608 4.8-1.664-1.696-0.032-3.104-1.152-3.616-2.688 0.224 0.032 0.48 0.064 0.736 0.064 0.352 0 0.576-0.032 0.896-0.128-1.76-0.352-3.232-1.92-3.232-3.776v-0.064c0.32 0.288 1.248 0.448 1.856 0.48-1.024-0.704-1.664-1.888-1.664-3.2 0-0.704 0.224-1.376 0.544-1.952 1.888 2.336 4.768 3.872 7.968 4.032-0.032-0.288-0.064-0.576-0.064-0.864 0-2.144 1.728-3.872 3.872-3.872 1.12 0 2.112 0.48 2.816 1.216 0.864-0.16 1.696-0.48 2.464-0.928-0.288 0.896-0.896 1.664-1.696 2.144 0.768-0.096 1.536-0.288 2.208-0.608-0.512 0.768-1.184 1.44-1.92 1.984z"
    />
  ),
});

export default TwitterIcon;
