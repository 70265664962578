export const SOCIALLINKS = {
  twitter: 'https://twitter.com/thebouqsco',
  facebook: 'https://www.facebook.com/TheBouqsCo',
  pinterest: 'https://www.pinterest.com/thebouqsco/',
  instagram: 'https://www.instagram.com/thebouqsco/',
};

export const WEDDINGSSUBORGLINKS = {
  facebook: 'https://www.facebook.com/bouqscoevents/',
  instagram: 'https://www.instagram.com/thebouqscoweddings/',
  pinterest: 'https://www.pinterest.com/thebouqsco/',
};
